<div
  class="relative flex h-full items-center justify-center px-16 py-20 max-md:max-w-full max-md:px-5"
>
  <div class="flex flex-col items-center justify-center text-center">
    <div class="img-box flex justify-center">
      <img src="assets/images/without-permission.png" class="h-40" alt="" />
    </div>
    <div class="subTitle-box my-4 flex flex-col items-center justify-center">
      <div>This site is no longer operational.</div>
      <div>Please refer to your provider for more information.</div>
    </div>
  </div>
</div>
