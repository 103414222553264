import { environment } from '../environments/environment';

export function getRootHostDomain() {
  // 处理后续的正式域名处理，如果是正式环境，则返回正式域名，否则返回测试域名
  // if (
  //   (window.location.hostname.indexOf(environment.onlineRootDomain) >= 0 ||
  //     environment.customDomains.indexOf(window.location.host) >= 0) &&
  //   window.location.hostname.indexOf('.') >= 0
  // ) {
  //   // const regex = /.*\.((.*?)\.(.*))/; // 匹配根域名
  //   // const match = regex.test(window.location.hostname);
  //   // if (match) {
  //   //   return '.' + RegExp.$1; // 返回根域名
  //   // }
  //   // return window.location.hostname; // 如果不是根域名，返回当前域名
  //   // 线上域名已调整为 eu.bitestream.co
  //   return '.' + window.location.hostname;
  // }
  // return window.location.hostname;

  if (environment.customDomains.indexOf(window.location.host) >= 0) {
    return window.location.hostname;
  }
  return `.${window.location.hostname}`;
}

export function getRootDomain() {
  // 处理后续的正式域名处理，如果是正式环境，则返回正式域名，否则返回测试域名
  if (environment.production) {
    const regex = /.*?\.(.*)/; // 匹配根域名
    const match = regex.test(window.location.hostname);
    if (match) {
      return '.' + RegExp.$1; // 返回根域名
    }
  }
  return '.' + window.location.hostname;
}

export function getCurrentSubdomain() {
  return environment.production ||
    window.location.hostname.indexOf(environment.onlineRootDomain) >= 0
    ? window.location.hostname.split('.')[0]
    : location.hostname;
}

export function getRootWorkspace() {
  let loginRoute = '/workspace/';
  if (getRootHostDomain().indexOf('localhost') >= 0) {
    // 本地调试，兜底方案
    loginRoute = window.location.origin + '/workspace/';
  } else if (
    window.location.host.indexOf(environment.onlineRootDomain) >= 0 ||
    environment.customDomains.indexOf(window.location.host) >= 0
  ) {
    loginRoute = `${window.location.protocol}//${window.location.host}/workspace/`;
  } else if (environment.production) {
    const regex = /(.*?)\.((.*?)\.(.*))/; // 匹配根域名
    const match = regex.test(window.location.hostname);
    if (match) {
      loginRoute = `${window.location.protocol}//${RegExp.$2}/workspace/`;
    }
  }
  return loginRoute;
}
