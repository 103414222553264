import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthType, HttpStatusEnum, LpConfiguration } from '@stream/models';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, pluck, tap } from 'rxjs/operators';
import { SendEmail } from 'src/app/ngx-utils';

import { Location } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';
import { getRootHostDomain } from 'src/utils/domain.util';
import { ClientApi, HttpAccountApi, HttpSignInApi, HttpStaffApi } from '../http/http-api';
import { CheckResetToken, SetPasswordStatus } from '../model/account.model';
import {
  DomainToken,
  GpLoginStatusEnum,
  InviteCodeStatus,
  RefreshTokenRes,
  SignInDTO,
  SignInPayload,
  WorkspaceToken
} from '../model/auth.model';
import { GPAuthStore } from '../store/gp-auth.store';
import { CommonStoreKey, GPSignInStoreKey, GPSignUpStoreKey } from '../store/store-keys';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GpAuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute
  ) {}

  tenantSubject = new Subject<LpConfiguration>();
  tenantInfo$ = this.tenantSubject.asObservable();
  tenantInfo!: LpConfiguration;
  public gpAuthStore = new GPAuthStore(this.localStorage);

  get tenantId() {
    return this.tenantInfo?.tenantId;
  }

  get tenantPrefix() {
    return this.tenantInfo?.prefixValue;
  }

  goToGp() {
    const domainTokens = this.gpAuthStore.domainTokenGroup[0]?.domains || [];
    const tokenId = domainTokens.find(item => item.prefixValue === this.tenantPrefix)?.tokenId;
    if (this.tenantInfo?.fullDomainValue) {
      window.open(
        `${window.location.protocol}//${this.tenantInfo.fullDomainValue}/gp/dashboard?sid=${tokenId}`,
        '_self'
      );
    }
  }

  getTenantInfo(prefixValue: string) {
    return this.http
      .get<
        Rest<{
          gpCommonStatusEnum: HttpStatusEnum;
          tenantInfoResponse: LpConfiguration;
        }>
      >(ClientApi.TenantInfo, {
        params: { prefixValue }
      })
      .pipe(
        tap(res => {
          const { gpCommonStatusEnum, tenantInfoResponse } = res.data;
          if (gpCommonStatusEnum === HttpStatusEnum.Success) {
            this.tenantSubject.next(tenantInfoResponse);
            this.tenantInfo = tenantInfoResponse;
          } else {
            this.router.navigate(['/not-found']);
          }
        }),
        pluck('data', 'tenantInfoResponse'),
        catchError(error => {
          if (error?.error?.code === 'B-00035') {
            // tenant was disabled
            this.router.navigate(['/domain-not-operational']);
          } else {
            throw error;
          }
          return of();
        })
      );
  }

  goToGpSignIn(prefixValue: string = '') {
    // 本地环境调试
    if (environment.production) {
      window.open(`${location.origin}/workspace/gp-auth/${prefixValue}/sign-in`, '_self');
    } else {
      window.open(`${location.origin}/gp-auth/${prefixValue}/sign-in`, '_self');
    }
  }

  /**
   * 发送重置密码邮件
   * @param email
   * @param redirectUrl 重置完密码后跳转地址
   * @param landPageUrl 邮件中的链接地址
   * @param tenantId 租户ID
   */
  @SendEmail(CommonStoreKey.ForgotPassword)
  sendForgotPasswordMail(
    email: string,
    redirectUrl: string,
    landPageUrl: string,
    tenantId?: string
  ) {
    return this.http.post<Rest>(HttpAccountApi.SendResetMail, {
      email,
      redirectUrl,
      landPageUrl,
      tenantId
    });
  }

  /**
   * 重置密码的落地页，调用检查token是否效接口
   * @param token
   */
  checkResetToken(token: string) {
    return this.http.get<Rest<CheckResetToken>>(HttpAccountApi.CheckResetToken, {
      params: {
        token
      }
    });
  }

  /**
   * 设置密码
   * @param tempToken
   * @param password
   */
  resetPassword(tempToken: string, password: string) {
    return this.http.post<Rest<{ status: SetPasswordStatus }>>(HttpAccountApi.ResetPassword, {
      tempToken,
      password
    });
  }

  /**
   * 验证密码
   * @param params
   */
  verifyPassword(params: {
    tempToken: string;
    password: string;
    email: string;
    tenantId?: string;
  }) {
    return this.http
      .post<
        Rest<{
          gpLoginStatusEnum: string;
          message: string;
          gpLoginInfo: DomainToken[];
        }>
      >(HttpAccountApi.VerifyPassword, params)
      .pipe(
        tap(({ data: { gpLoginInfo, gpLoginStatusEnum } }) => {
          if (gpLoginStatusEnum === 'success' && gpLoginInfo) {
            const domainToken = gpLoginInfo.find(
              ({ prefixValue: prefixValueFinder }) => this.tenantPrefix === prefixValueFinder
            );
            if (domainToken) {
              this.gpAuthStore.tempDomainToken = domainToken;
              this.gpAuthStore.addWorkspaceToken({
                tokenInfo: {
                  refreshToken: domainToken.refreshToken,
                  accessToken: domainToken.accessToken
                }
              });
              this.gpAuthStore.addDomainToken(params.email, gpLoginInfo);
            }
          }
        })
      );
  }

  /**
   * @description 发送验证邮件
   */
  @SendEmail(GPSignUpStoreKey.SendCodeTimer)
  sendVerifyMail(email: string, tenantId?: string) {
    this.gpAuthStore.signUpEmail = email;
    const params: any = {
      email
    };
    if (tenantId) {
      params.tenantId = tenantId;
    }
    return this.http.get<Rest<boolean>>(HttpAccountApi.SendVerifyMail, {
      params
    });
  }

  sendMfaAssistanceEmail(ticket: string) {
    return this.http.post<Rest<{}>>(
      HttpAccountApi.MfaAssistanceEmail,
      {},
      {
        params: { ticket }
      }
    );
  }

  activeStaff(params: {
    mfaTicketForActive: string;
    email: string;
    userName: string;
    password: string;
    tempToken: string;
    inviteLinkCode: string;
  }) {
    return this.http
      .post<
        Rest<{
          message: string;
          saveStatus: boolean;
          gpLoginInfo: DomainToken[];
          prefixValue: string;
          tokenInfo: WorkspaceToken;
        }>
      >(HttpStaffApi.ActiveStaff, params)
      .pipe(
        tap(({ data: { gpLoginInfo, saveStatus, prefixValue, tokenInfo } }) => {
          if (saveStatus) {
            const domainToken = gpLoginInfo.find(
              ({ prefixValue: prefixValueFinder }) => prefixValue === prefixValueFinder
            ) as DomainToken;
            this.gpAuthStore.tempDomainToken = domainToken;
            this.gpAuthStore.addWorkspaceToken({ tokenInfo });
            this.gpAuthStore.addDomainToken(params.email, gpLoginInfo);
          }
        })
      );
  }

  verifyLinkCode(code: string) {
    return this.http.get<
      Rest<{
        status: InviteCodeStatus;
        message: string;
        mfaTicketForActive: string;
        passwordExists: boolean;
      }>
    >(HttpAccountApi.VerifyLinkCode, { params: { code } });
  }

  loginWithPassword(params: SignInPayload) {
    return this.http.post<Rest<SignInDTO>>(HttpSignInApi.LoginWithPassword, params).pipe(
      tap(({ data: { gpLoginStatusEnum, gpLoginInfo, tokenInfo } }) => {
        if (gpLoginStatusEnum === GpLoginStatusEnum.Success) {
          this.gpAuthStore.addWorkspaceToken({
            tokenInfo
          });
          this.gpAuthStore.addDomainToken(params.email, gpLoginInfo);
        }
      }),
      catchError(err => {
        if (err?.error?.code === 'B-00035') {
          this.router.navigate(['/domain-not-operational']);
        }
        return throwError(err);
      })
    );
  }

  /**
   * @description 跳转到 GP
   * @param domain
   */
  goToDashboard(domain: DomainToken) {
    let url = '';
    const hostDomain = getRootHostDomain();
    const { onlineRootDomain, production } = environment;
    const { origin, protocol, host } = window.location;
    const { prefixValue, tokenId } = domain;
    const isLocalHost = hostDomain.indexOf('localhost') > -1;
    const isOnlineRootDomain = host.indexOf(onlineRootDomain) > -1;
    const returnUrl = this.route.snapshot.queryParams['returnUrl'];
    const urlSuffix = this.getGPLandingPageUrl(tokenId, returnUrl);
    if (production || isOnlineRootDomain) {
      url = Location.joinWithSlash(`${protocol}//${prefixValue}${hostDomain}/gp`, urlSuffix);
    } else if (isLocalHost) {
      const _urlSuffix = decodeURIComponent(urlSuffix);
      url = Location.joinWithSlash(
        origin,
        `gp-auth/${prefixValue}/simulate-landing?suffix=${_urlSuffix}`
      );
      this.localStorage.store(GPSignInStoreKey.DomainToken, domain);
    } else {
      url = Location.joinWithSlash(`${origin}/gp`, urlSuffix);
    }
    this.gpAuthStore.clearSignUpStore();
    window.location.replace(url);
  }

  getGPLandingPageUrl(sid: string, returnUrl?: string) {
    let url = '/dashboard';
    if (!returnUrl) {
      return `${url}?sid=${sid}`;
    }
    if (/\?.+=.*/.test(returnUrl)) {
      return `${returnUrl}&sid=${sid}`;
    }
    return `${returnUrl}?sid=${sid}`;
  }

  verifyMailCode(email: string, code: string) {
    return this.http
      .get<
        Rest<{
          temporaryToken: string;
          verifyResult: boolean;
        }>
      >(HttpAccountApi.VerifyMailCode, {
        params: { email, code }
      })
      .pipe(
        tap(({ data: { temporaryToken, verifyResult } }) => {
          if (verifyResult) {
            this.gpAuthStore.tempToken = temporaryToken;
            this.gpAuthStore.signUpEmail = email;
          }
        })
      );
  }

  session(
    params: {
      authType: AuthType;
      mfaCode?: string;
      mfaType?: string;
      mfaTicket: string;
      validatorLinkCode?: string;
    },
    email: string
  ) {
    return this.http.post<Rest<SignInDTO>>(HttpSignInApi.Session, params).pipe(
      tap(({ data: { gpLoginStatusEnum, gpLoginInfo, tokenInfo } }) => {
        if (gpLoginStatusEnum === GpLoginStatusEnum.Success) {
          this.gpAuthStore.addWorkspaceToken({ tokenInfo });
          this.gpAuthStore.addDomainToken(email, gpLoginInfo);
        }
      })
    );
  }

  /**
   * 保存员工信息
   * @param staffInfo 员工信息
   */
  saveStaff(userName: string) {
    const email = this.gpAuthStore.signUpEmail;
    return this.http
      .post<
        Rest<{
          message: string;
          saveStatus: boolean;
          gpLoginInfo: DomainToken[];
          prefixValue: string;
          tokenInfo: WorkspaceToken;
        }>
      >(HttpStaffApi.SaveStaff, {
        userName,
        email,
        tempToken: this.gpAuthStore.tempToken,
        inviteLinkCode: this.gpAuthStore.inviteCode
      })
      .pipe(
        tap(({ data: { gpLoginInfo, saveStatus, prefixValue, tokenInfo } }) => {
          if (saveStatus) {
            const domainToken = gpLoginInfo.find(
              ({ prefixValue: prefixValueFinder }) => prefixValue === prefixValueFinder
            )!;
            this.gpAuthStore.tempDomainToken = domainToken;
            this.gpAuthStore.addWorkspaceToken({ tokenInfo });
            this.gpAuthStore.addDomainToken(email, gpLoginInfo);
          }
        })
      );
  }

  /**
   * @description 设置密码、鉴权
   * @param password
   * @returns
   */
  setPassword(password: string) {
    return this.http.post<Rest<{ status: SetPasswordStatus }>>(
      HttpAccountApi.SetPassword,
      {
        password
      },
      {
        headers: {
          AccessToken: this.gpAuthStore.tempDomainToken.accessToken
        }
      }
    );
  }

  verifyInviteCode(code: string, tempToken: string) {
    return this.http.get<
      Rest<{
        status: InviteCodeStatus;
        message: string;
        mfaTicketForActive: string;
        passwordExists: boolean;
      }>
    >(HttpStaffApi.VerifyInviteCode, { params: { code, tempToken } });
  }

  /**
   * @description 注册邮箱存在性检查
   */
  isMailExist(email: string): Observable<Rest<boolean>> {
    return this.http.get<Rest<boolean>>(HttpAccountApi.CheckEmailValid, {
      params: { email, code: this.gpAuthStore.inviteCode }
    });
  }

  /**
   * 刷新token
   * @returns 新的accessToken
   */
  refreshToken() {
    return this.http
      .get<Rest<RefreshTokenRes>>(HttpSignInApi.RefreshToken, {
        headers: {
          RefreshToken: this.gpAuthStore.refreshToken ?? ''
        }
      })
      .pipe(
        tap(({ data }) => {
          if (data?.tokenResponse) {
            const { accessToken, refreshToken } = data.tokenResponse;
            this.gpAuthStore.addWorkspaceToken({ tokenInfo: data.tokenResponse });
            this.gpAuthStore.domainToken = {
              ...this.gpAuthStore.domainToken,
              accessToken,
              refreshToken
            } as DomainToken;
          }
        }),
        map(({ data }) => data?.tokenResponse?.accessToken)
      );
  }

  /**
   * @description 发送邀请邮件
   */
  sendInviteEmail(emails: string[], rolesType: string[] | null = []) {
    return this.http.post<
      Rest<{ gpMailSentEnum: string; gpMailSentMessage: string; gpMailInviteEnum?: string }>
    >(
      HttpStaffApi.SendInviteEmail,
      { emails, rolesType },
      {
        headers: {
          AccessToken: this.gpAuthStore.inviteDomainToken?.accessToken || ''
        }
      }
    );
  }
}
