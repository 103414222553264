import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'stream-not-found',
  template: `
    <div class="flex h-screen w-screen items-center justify-center bg-white">
      <div class="flex items-center gap-x-[190px]">
        <div class="flex-1 text-center">
          <div class="text-[140px] font-bold text-[#07133A]">404</div>
          <p class="text-2xl">The page you visited dose not exist.</p>
        </div>
        <div class="flex-none">
          <img
            class="h-[280px] w-[280px]"
            src="./assets/images/404.png"
            alt=""
          />
        </div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {}
