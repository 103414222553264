import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { FilePreviewerComponent } from './file-previewer.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FilePreviewerService } from './file-previewer.service';
import { FormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [FilePreviewerComponent],
  imports: [
    CommonModule,
    FormsModule,
    CodemirrorModule,
    NzModalModule,
    NzIconModule,
    NzToolTipModule
  ],
  providers: [FilePreviewerService]
})
export class FilePreviewerModule {}
