import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ImageResizePipe } from "./image-resize.pipe";

@NgModule({
  declarations: [ImageResizePipe],
  imports: [CommonModule],
  exports: [ImageResizePipe],
})
export class ImageResizeModule {}
