import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'stream-inactivity-reminder',
  templateUrl: './stream-inactivity-reminder.component.html',
  styleUrls: ['./stream-inactivity-reminder.component.scss'],
})
export class AppInactivityReminderComponent implements OnInit {
  @Input() timeout = 2 * 60 * 60 * 1000; // 2 hour in milliseconds
  @Input() countdownColor = '#1A1821';
  @Input() countdownPlatform = '';

  @Output() onTimeout = new EventEmitter<void>();

  public showReminder = false;
  public countdown!: number;

  private lastActivityTimeKey: string = '';
  private showReminderKey: string = '';
  private countdownInterval?: number;

  constructor() {}

  ngOnInit(): void {
    this.lastActivityTimeKey = this.countdownPlatform + '|lastActivityTimeKey';
    this.showReminderKey = this.countdownPlatform + '|showReminderKey';
    this.syncActivityTimeWithStorage();
    this.startActivityCheck();
    window.addEventListener('storage', event => {
      if (event.key === this.lastActivityTimeKey || event.key === this.showReminderKey) {
        this.syncActivityTimeWithStorage();
      }
    });
  }

  @HostListener('window:mousemove')
  @HostListener('window:keypress')
  @HostListener('window:touchstart')
  @HostListener('window:touchend')
  resetActivityTime() {
    const now = Date.now();
    localStorage.setItem(this.lastActivityTimeKey, now.toString());
    this.showReminder = false;
    localStorage.setItem(this.showReminderKey, 'false');
    this.clearCountdown();
    this.syncActivityTimeWithStorage();
  }

  syncActivityTimeWithStorage() {
    const storedTime = localStorage.getItem(this.lastActivityTimeKey);
    const showReminderStored = localStorage.getItem(this.showReminderKey);
    const currentTime = Date.now();

    if (storedTime === null) {
      // Set the initial last activity time to now
      localStorage.setItem(this.lastActivityTimeKey, Date.now().toString());
      this.showReminder = false;
      localStorage.setItem(this.showReminderKey, 'false');
    } else {
      // Parse the stored time and proceed with existing logic
      const storedTimeInt = parseInt(storedTime, 10);
      this.showReminder = showReminderStored === 'true';
      if (currentTime - storedTimeInt > this.timeout) {
        localStorage.setItem(this.lastActivityTimeKey, currentTime.toString());
        this.showReminder = false;
        localStorage.setItem(this.showReminderKey, 'false');
      } else {
        if (this.showReminder) {
          const currentTime = Date.now();
          const timeRemaining = this.timeout - (currentTime - storedTimeInt);
          if (timeRemaining < 0) {
            this.clearCountdown();
          } else {
            this.startCountdown(timeRemaining);
          }
        }
      }
    }
  }

  startActivityCheck() {
    window.setInterval(() => {
      const currentTime = Date.now();
      const storedTime = parseInt(
        localStorage.getItem(this.lastActivityTimeKey) || '0',
        10,
      );
      const timeDiff = currentTime - storedTime;

      // fix timeout to 15 minutes
      if (timeDiff >= this.timeout - (15 * 60 * 1000) && !this.showReminder && storedTime !== 0) {
        this.showReminder = true;
        localStorage.setItem(this.showReminderKey, 'true');
        this.startCountdown(15 * 60 * 1000); // Start 15 minutes countdown
      } else if (timeDiff >= this.timeout && storedTime !== 0) {
        // time out, call the logout method, and clear all timers
        this.clearCountdown();
        localStorage.removeItem(this.showReminderKey);
        this.onTimeout.emit();
      }
    }, 1000); // Check every second
  }

  startCountdown(timeRemaining: number) {
    if (this.countdownInterval !== undefined) {
      clearInterval(this.countdownInterval);
    }

    this.countdown = Math.ceil(timeRemaining / 1000);
    const targetTime = Date.now() + timeRemaining;

    this.countdownInterval = window.setInterval(() => {
      const currentTime = Date.now();
      const remainingTime = targetTime - currentTime;
      this.countdown = Math.ceil(remainingTime / 1000);

      if (this.countdown <= 0) {
        this.clearCountdown();
      }
    }, 1000);
  }

  clearCountdown() {
    if (this.countdownInterval !== undefined) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = undefined;
    }
    this.showReminder = false;
    localStorage.setItem(this.showReminderKey, 'false');
    localStorage.removeItem(this.lastActivityTimeKey);
  }

  formatCountdown(countdown: number) {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  }

  ngOnDestroy(): void {
    if (this.countdownInterval !== undefined) {
      clearInterval(this.countdownInterval);
    }
  }
}
