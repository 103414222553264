import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { ClientApi, HttpSignInApi } from './http-api';
import { Router } from '@angular/router';
import { GpAuthService } from '../service/gp-auth.service';

const WHITELIST = [HttpSignInApi.RefreshToken];
const API_USE_GP_TOKEN_LIST: string[] = [ClientApi.TenantInfo];

@Injectable()
export class GPAuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private gpAuthService: GpAuthService) {}

  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<string | null>(null);

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const useGPRequest =
      this.router.url.indexOf('/gp-auth') > -1 || API_USE_GP_TOKEN_LIST.includes(request.url);
    if (!useGPRequest) {
      return next.handle(request);
    }
    const { accessToken } = this.gpAuthService.gpAuthStore;
    const isAddedToken = request?.headers?.has('AccessToken');
    if (!isAddedToken && !WHITELIST.includes(request.url as any)) {
      return next.handle(this.addToken(request, accessToken)).pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handleUnauthorizedError(request, next);
          }
          return throwError(error);
        })
      );
    }

    return next.handle(request);
  }

  /**
   * 添加Token到请求头
   * @param request 请求
   * @param token Token
   * @returns 添加Token后的请求
   */
  addToken(request: HttpRequest<unknown>, token: string | null) {
    if (!token) {
      return request;
    }
    return request.clone({
      setHeaders: {
        AccessToken: token
      }
    });
  }

  handleUnauthorizedError(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.gpAuthService.refreshToken().pipe(
        switchMap(accessToken => {
          this.refreshTokenSubject.next(accessToken);
          return next.handle(this.addToken(request, accessToken));
        }),
        catchError(error => {
          this.gpAuthService.goToGpSignIn();
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    }
    return this.refreshTokenSubject.pipe(
      filter(Boolean),
      take(1),
      switchMap(accessToken => next.handle(this.addToken(request, accessToken)))
    );
  }
}
