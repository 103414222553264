export const isProdEnvironment = () => {
  if (
    window.location.hostname.indexOf('bite.dev') > -1 ||
    window.location.hostname.indexOf('localhost') > -1
  ) {
    // this is the dev environment or local environment
    return false;
  }
  // this is the prod environment, may be contains some other customize domain in production
  return true;
};
