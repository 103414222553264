<div
  class="flex h-full min-h-7 w-full min-w-7 items-center justify-center rounded"
  (click)="handleCopy()"
>
  <span
    *ngIf="!hasCopied()"
    class="flex-none cursor-pointer text-xl text-inherit"
    nz-icon
    nzIconfont="icon-Base"
  ></span>
  <span
    class="text-xl text-green-500"
    *ngIf="hasCopied()"
    nz-icon
    nzType="check"
    nzTheme="outline"
  ></span>
</div>
