export enum FundamentalFcaApprovedEnum {
  Yes = 'YES',
  No = 'NO'
}

export enum FundamentalLiquidityEnum {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY',
  ThreeToFiveYears = 'THREE_TO_FIVE_YEARS',
  MoreThanFiveYears = 'MORE_THAN_FIVE_YEARS'
}

export enum FundamentalOnlineEnum {
  AllowInvestment = 'ALLOW_INVESTMENT',
  OnlyExpressInterest = 'ONLY_EXPRESS_INTEREST',
  InvestmentNotAllowed = 'INVESTMENT_NOT_ALLOWED'
}

export enum FundamentalStatusEnum {
  ComingSoon = 'COMING_SOON',
  Open = 'OPEN',
  ClosingSoon = 'CLOSING_SOON',
  Closed = 'CLOSED'
}

export enum FundamentalStyleEnum {
  Income = 'INCOME',
  CapitalProtected = 'CAPITAL_PROTECTED',
  CapitalGrowth = 'CAPITAL_GROWTH',
  Discretionary_Macro = 'DISCRETIONARY_MACRO',
  Absolute_Return = 'ABSOLUTE_RETURN',
  Fine_Art = 'FINE_ART',
  Impact = 'IMPACT',
  ArtAndValuablesCredit = 'ART_AND_VALUABLES_CREDIT',
  FundOfFunds = 'FUND_OF_FUNDS',
  DIRECT_INVESTMENT = 'DIRECT_INVESTMENT',
  ASSET_ALLOCATION = 'ASSET_ALLOCATION'
}

export enum FundamentalTargetReturnEnum {
  EMPTY = 'EMPTY',
  LT5 = 'LT5',
  EQ5 = 'EQ5', // '5%
  EQ5TO10 = 'EQ5TO10',
  EQ5TO6 = 'EQ5TO6',
  EQ6P5 = 'EQ6P5',
  EQ6TO7 = 'EQ6TO7',
  EQ7TO8 = 'EQ7TO8',
  EQ8 = 'EQ8',
  EQ8TO9 = 'EQ8TO9',
  EQ9 = 'EQ9',
  EQ9TO10 = 'EQ9TO10',
  EQ10 = 'EQ10',
  EQ10P5 = 'EQ10P5',
  EQ11 = 'EQ11',
  EQ10TO11 = 'EQ10TO11',
  EQ10TO12 = 'EQ10TO12',
  EQ11P5 = 'EQ11P5',
  EQ11TO12 = 'EQ11TO12',
  EQ12 = 'EQ12',
  EQ12P5 = 'EQ12P5',
  EQ11TO13 = 'EQ11TO13',
  EQ12TO13 = 'EQ12TO13',
  EQ13 = 'EQ13',
  EQ12TO14 = 'EQ12TO14',
  EQ12TO15 = 'EQ12TO15',
  EQ13TO14 = 'EQ13TO14',
  EQ14 = 'EQ14',
  EQ14TO15 = 'EQ14TO15',
  EQ10TO15 = 'EQ10TO15',
  EQ15 = 'EQ15',
  EQ16 = 'EQ16',
  EQ17 = 'EQ17',
  EQ15TO17 = 'EQ15TO17',
  EQ15TO18 = 'EQ15TO18',
  EQ18 = 'EQ18',
  EQ19 = 'EQ19',
  EQ15TO20 = 'EQ15TO20',
  EQ20 = 'EQ20',
  EQ20TO30 = 'EQ20TO30',
  GT20 = 'GT20',
  EQ30 = 'EQ30',
  EQ30TO40 = 'EQ30TO40',
  GT40 = 'GT40',
  NET_IRR_20 = 'NET_IRR_20',
  ABSOLUTE_RETURN = 'ABSOLUTE_RETURN'
}

export enum FundamentalTargetReturnV2Enum {
  EQ0TO5 = 'EQ0TO5',
  EQ5TO10 = 'EQ5TO10',
  EQ10TO15 = 'EQ10TO15',
  EQ15TO20 = 'EQ15TO20',
  EQ20TO30 = 'EQ20TO30',
  EQ30TO40 = 'EQ30TO40',
  GT40 = 'GT40',
  OTHER = 'OTHER'
}

export enum FundamentalTypeEnum {
  OpenEnded = 'OPEN_ENDED',
  CloseEnded = 'CLOSE_ENDED'
}

export enum GeographicFocusEnum {
  Europe = 'EUROPE',
  USA = 'USA',
  ASIA = 'ASIA',
  Global = 'GLOBAL',
  EmergingMarkets = 'EMERGING_MARKETS',
  Africa = 'AFRICA',
  LATAM = 'LATAM',
  Brazil = 'BRAZIL',
  MENA = 'MENA',
  Australia = 'AUSTRALIA',
  INDIA = 'INDIA'
}

export interface ProductOpenedCountry {
  countryAlpha2Code: string;
  countryAlpha3Code: string;
  countryId: string;
  countryName: string;
  countryNameric: string;
  id: string;
  productId: string;
}

export enum ProductStatusEnum {
  Draft = 'DRAFT',
  Released = 'RELEASED',
  Changing = 'CHANGING'
}

export enum StrategiesEnum {
  Agriculture = 'AGRICULTURE',
  'Agricultural Commodities' = 'AGRICULTURAL_COMMODITIES',
  'Asset Allocation' = 'ASSET_ALLOCATION',
  'Bridge Round' = 'BRIDGE_ROUND',
  Carbon = 'CARBON',
  'Co-Investments' = 'CO_INVESTMENTS',
  'Commodities' = 'COMMODITIES',
  'Convertible Note' = 'CONVERTIBLE_NOTE',
  Credit = 'CREDIT',
  Cryptocurrency = 'CRYPTOCURRENCY',
  'Digital Assets' = 'DIGITAL_ASSETS',
  'Digital Securities' = 'DIGITAL_SECURITIES',
  'Direct Investment' = 'DIRECT_INVESTMENT',
  Energy = 'ENERGY',
  'Event-Driven' = 'EVENT_DRIVEN',
  Farmland = 'FARMLAND',
  'Fixed Income' = 'FIXED_INCOME',
  'Global Macro' = 'GLOBAL_MACRO',
  'Hedge Fund' = 'HEDGE_FUND',
  Infrastructure = 'INFRASTRUCTURE',
  'Long/Short Equity' = 'LONG_OR_SHORT_EQUITY',
  'Long-Only' = 'LONG_ONLY',
  'Multi-Asset' = 'MULTI_ASSET',
  'Multi-Manager' = 'MULTI_MANAGER',
  'Multi-Strategy' = 'MULTI_STRATEGY',
  'Pre-Series A' = 'PRE_SERIES_A',
  'Private Credit' = 'PRIVATE_CREDIT',
  'Private Equity' = 'PRIVATE_EQUITY',
  'Private Markets' = 'PRIVATE_MARKETS',
  'Public Credit' = 'PUBLIC_CREDIT',
  'Real Estate' = 'REAL_ESTATE',
  SAFE = 'SAFE',
  Secondaries = 'SECONDARIES',
  Seed = 'SEED',
  'Series A' = 'SERIES_A',
  'Series B' = 'SERIES_B',
  'Series C' = 'SERIES_C',
  'Structured Products' = 'STRUCTURED_PRODUCTS',
  Systematic = 'SYSTEMATIC',
  Transport = 'TRANSPORT',
  'Venture Capital' = 'VENTURE_CAPITAL',
  Other = 'OTHER'
}

export enum ContractLogicType {
  SubscriptionDocument = 'SUBSCRIPTION_DOCUMENT',
  InvestorSuitabilityQuestionnaire = 'INVESTOR_SUITABILITY_QUESTIONNAIRE',
  IndividualSelfCertification = 'INDIVIDUAL_SELF_CERTIFICATION',
  W8BEN = 'W8BEN',
  W9 = 'W9',
  PooledVehicleStatement = 'POOLED_VEHICLE_STATEMENT',
  ProfessionalManagedVehicleDeclaration = 'PROFESSIONAL_MANAGED_VEHICLE_DECLARATION',
  ControllingPerson = 'CONTROLLING_PERSON',
  EntitySelfCertification = 'ENTITY_SELF_CERTIFICATION',
  W8BEN_E = 'W8BEN_E',
  NDA = 'NDA'
}

export enum FundamentalTargetReturnType {
  flat = 'FLAT',
  range = 'RANGE',
  other = 'OTHER'
}

export interface Product {
  brandingBackgroundImage: string;
  brandingDetailBackgroundImage: string;
  brandingLogo: string;
  draftChangeTimes: Date;
  draftReferencedId: string;
  fundamentalComplianceCheckEmail: string;
  fundamentalFcaApproved: FundamentalFcaApprovedEnum;
  fundamentalLiquidity: FundamentalLiquidityEnum;
  fundamentalLiquidityDescription: string;
  fundamentalName: string;
  fundamentalOnline: FundamentalOnlineEnum;
  fundamentalStatus: FundamentalStatusEnum;
  fundamentalStyle: FundamentalStyleEnum;
  fundamentalSubscriptionEmail: string;
  fundamentalTargetFund: string;
  fundamentalTargetFundManager: string;
  fundamentalTargetReturn?: FundamentalTargetReturnEnum;
  fundamentalTargetReturnType: FundamentalTargetReturnType;
  fundamentalTargetReturnLowerBound?: number;
  fundamentalTargetReturnUpperBound?: number;
  fundamentalType: FundamentalTypeEnum;
  geographicFocus: GeographicFocusEnum[];
  id: string;
  inTrash: boolean;
  minimumInvestment: number;
  minimumInvestmentAtSecTime: Date;
  openedCountries: ProductOpenedCountry[];
  orderIndex: number;
  productStatus: ProductStatusEnum;
  releasedTimes: Date;
  strategies: StrategiesEnum[];
  subsequentInvestment: number;
  tenantId: string;
  currency: string;
  pinned: boolean;
}

export interface ChatAsset {
  assetId: string;
  asset: string;
}

export interface ChatAssetCategory {
  id: string;
  title: string;
  requiredSubscription: boolean;
  includeSales: boolean;
  notificationPreference: string;
  default: boolean;
  relatedAssetsIds: string[];
  relatedAssets: string[];
  recipientsIds: string[];
  locations: string[];
}
