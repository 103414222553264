import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, ValidatorFn } from '@angular/forms';
import { NzInputDirective } from 'ng-zorro-antd/input';

enum PasswordError {
  MinLength = 'at least 10 characters',
  Lowercase = 'a lowercase letter',
  Uppercase = 'an uppercase letter',
  Number = 'a number(ex. 0 - 9)',
  Special = 'a special character',
}
@Component({
  selector: 'stream-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.less'],
})
export class PasswordInputComponent implements OnInit, ControlValueAccessor {
  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl
  ) {
    if (this.ngControl !== null) {
      ngControl.valueAccessor = this;
    }
  }

  @Input()
  inputClass: string | string[] = '';

  @Input()
  nzSize: NzInputDirective['nzSize'] = 'default';

  @Input()
  suffix: TemplateRef<any> | string = ''; // eslint-disable-line

  disabled = false;

  $value = '';

  passwordVisible = false;

  passwordValidators: [PasswordError, RegExp][] = [
    [PasswordError.MinLength, /^.{10,}$/],
    [PasswordError.Lowercase, /[a-z]/],
    [PasswordError.Uppercase, /[A-Z]/],
    [PasswordError.Number, /\d/],
    [
      PasswordError.Special,
      // eslint-disable-next-line no-useless-escape
      /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·！￥…（）《》？：“”【】、；‘，。]/,
    ],
  ];

  @Input()
  errorStatus = false;

  @ViewChild('errorList')
  errorList!: TemplateRef<any>; // eslint-disable-line

  @Output()
  focus = new EventEmitter(); // eslint-disable-line

  @Output()
  blur = new EventEmitter(); // eslint-disable-line

  @Input()
  placeholder = 'Enter password';

  onTouch: () => unknown = () => null;

  passwordOnChange: (value: string) => void = (_value: string) => null;

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  get value() {
    return this.$value;
  }

  set value(value: string) {
    this.$value = value;
    this.passwordOnChange(this.$value);
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.passwordOnChange = fn;
  }

  ngOnInit(): void {
    this.ngControl.control?.setValidators(
      this.passwordValidators.map(([err, reg]) =>
        this.passwordValidator(err, reg)
      )
    );
    this.ngControl.control?.updateValueAndValidity();
  }

  passwordValidator(err: PasswordError, reg: RegExp): ValidatorFn {
    return (control) => (reg.test(control.value) ? null : { [err]: true });
  }
}
