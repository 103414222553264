export enum SignUpStoreKey {
  TempToken = 'TEMP_TOKEN',
  SignUpEmail = 'SIGN_UP_EMAIL',
  /** 邮箱邀请码  */
  InviteCode = 'INVITE_CODE',
  /** 正在注册或加入的公司名 */
  CompanyName = 'COMPANY_NAME',
  /** 发送验证邮件间隔 */
  SendCodeTimer = 'SEND_CODE_TIMER',
  GetShareAbleLinkTimer = 'GET_SHAREABLE_LINK_TIMER',
  /** 邀请domainToken*/
  InviteDomainToken = 'INVITE_DOMAIN_TOKEN',
  /** domain信息*/
  DomainPrefix = 'DOMAIN_PREFIX'
}

export enum SignInStoreKey {
  DomainTokenGroup = 'DOMAIN_TOKEN_GROUP',
  SignInUserEmail = 'SIGN_IN_USER_EMAIL',
  DomainToken = 'DOMAIN_TOKEN',
  SendCodeTimer = 'SIGN_IN_SEND_CODE_TIMER',
  ManageClickToken = 'MANAGE_CLICK_TOKEN',
  TenantId = 'TENANT_ID',
  RefreshToken = 'REFRESH_TOKEN'
}

/**
 * @description GP 登录存储键，和 workspace 区分开。
 */
export enum GPSignInStoreKey {
  DomainTokenGroup = 'GP_DOMAIN_TOKEN_GROUP',
  DomainToken = 'GP_DOMAIN_TOKEN',
  SignInUserEmail = 'GP_SIGN_IN_USER_EMAIL'
}

/**
 * @description GP 注册存储键，和 workspace 区分开。
 */
export enum GPSignUpStoreKey {
  SignUpEmail = 'GP_SIGN_UP_EMAIL',
  TempToken = 'GP_TEMP_TOKEN',
  InviteCode = 'GP_INVITE_CODE',
  SendCodeTimer = 'GP_SEND_CODE_TIMER',
  /** 正在注册或加入的公司名 */
  CompanyName = 'GP_COMPANY_NAME',
  InviteDomainToken = 'GP_INVITE_DOMAIN_TOKEN',
  DomainPrefix = 'GP_DOMAIN_PREFIX'
}

export enum GlobalStoreKey {
  DeviceIdentity = 'DEVICE_IDENTITY',
  DeviceBrowserType = 'DEVICE_BROWSER_TYPE',
  DeviceOSType = 'DEVICE_OS_TYPE'
}

export enum CommonStoreKey {
  /** 发送验证邮件间隔 */
  SendCodeTimer = 'SEND_CODE_TIMER',
  ForgotPassword = 'FORGOT_PASSWORD',
  ForgotPasswordEmail = 'FORGOT_PASSWORD_EMAIL'
}
