<div
  class="h-full w-full cursor-text overflow-auto border border-solid border-gray-300 px-3 py-2 focus-within:border-primary-0 hover:border-primary-0"
  [ngClass]="formError ? 'border-red-500' : ''"
  (click)="input.focus()"
>
  <span *ngIf="!formArray.value.length" class="text-xs leading-4 text-gray-300"> </span>
  <nz-tag
    *ngFor="let email of formArray.controls; let i = index"
    nzMode="closeable"
    class="mb-1 mr-1"
    [nzColor]="email.valid ? 'success' : 'error'"
    (nzOnClose)="formArray.removeAt(i)"
    #tag
    >{{ email.value }}</nz-tag
  >
  <input
    #input
    (keydown)="onKeyDown($event)"
    (blur)="addEmail()"
    (input)="valueChange.emit($event)"
    type="email"
    title=""
    class="inline-block w-full border-none bg-transparent outline-none focus:shadow-none"
    [placeholder]="placeholder"
    [formControl]="inputControl"
  />
</div>
