import { LocalStorageService } from "ngx-webstorage";
import { Observable, of, ReplaySubject, Subject, timer } from "rxjs";
import { map, scan, shareReplay, switchMap, tap } from "rxjs/operators";

import { CountModule } from "./count.module";

/* eslint-disable @typescript-eslint/ban-types */
const DEFAULT_DELAY_SECOND = 60;

const delayMap = new Map<string, number>();

const subjectMap = new Map<string, Subject<number>>();

const shareSubjectMap = new Map<Subject<number>, Observable<number>>();

function getTimerSubject(key: string) {
  const localStorageService = CountModule.injector?.get(LocalStorageService);
  if (subjectMap.has(key)) {
    return subjectMap.get(key) as ReplaySubject<number>;
  }
  const subject = new ReplaySubject<number>(1);
  subjectMap.set(key, subject);
  subject?.next(Number(localStorageService?.retrieve(key)) || 0);
  return subject;
}

/**
 * 发送邮件
 * @param storeKey 本地存储最后发送时间的键
 * @param delay 发送间隔 单位为秒
 */
export function CountReset(storeKey: string, delay = DEFAULT_DELAY_SECOND) {
  function storeCurrentTime() {
    const now = Date.now();

    localStorageService.store(storeKey, now.toString());
    getTimerSubject(storeKey)?.next(now);
  }
  const localStorageService = CountModule.injector?.get(LocalStorageService);
  return function (
    _target: Object,
    _propertyName: string,
    descriptor: TypedPropertyDescriptor<(...arg: any[]) => Observable<any>>
  ) {
    // Compatible test methods to avoid program blocking
    if (!localStorageService || !CountModule.injector) {
      console.warn("Unregister CountModule");
      return;
    }
    console.log("CountModule Register Successfully");
    delayMap.set(storeKey, delay * 1000);
    const method = descriptor.value;
    descriptor.value = function (...arg) {
      const result = method?.apply(this, arg) ?? of(null);
      if (result instanceof Observable) {
        return result.pipe(
          tap(() => {
            storeCurrentTime();
          })
        );
      } else {
        storeCurrentTime();
        return result;
      }
    };
  };
}

/**
 * 邮件倒计时
 * @param storeKey 存储最后发送时间的键
 */
export function CountDelay(storeKey: string) {
  return function (target: any, propertyName: string) {
    const delay = delayMap.get(storeKey) ?? DEFAULT_DELAY_SECOND * 1000;
    const subject = getTimerSubject(storeKey);
    
    const shareSubject =
      shareSubjectMap.get(subject) ??
      subject.pipe(
        map((lastSendTime) =>
          Math.ceil((delay - (Date.now() - lastSendTime)) / 1000)
        ),
        switchMap((remainingTime) =>
          timer(0, 1000).pipe(
            scan((seconds) => seconds - 1, remainingTime),
            map((seconds) => (seconds > 0 ? seconds : 0))
          )
        ),
        shareReplay(1)
      );
    shareSubjectMap.set(subject, shareSubject);
    target[propertyName] = shareSubject;
  };
}

export function clearCountDelay(storeKey: string) {
  const localStorageService = CountModule.injector?.get(LocalStorageService);
  const subject = getTimerSubject(storeKey);
  if (subject) {
    subject.next(0);
    localStorageService.clear(storeKey);
  }
}
