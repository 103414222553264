import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpDriveApi } from '../http/http-api';

@Injectable({
  providedIn: 'root',
})
export class DriveService {
  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  getFullUrlByHash(urlHash: string) {
    return this.http.get<
      Rest<{
        gpCommonStatusEnum: string;
        message: string;
        commonShortlink: {
          dataStatus: string;
          dataCreateTime: string;
          dataUpdateTime: string;
          dataIsDeleted: string;
          id: string;
          originalUrl: string;
          urlHash: string;
          fullUrlWithHash: string;
          accessCount: string;
          expireDate: string;
          linkStatus: string;
          redirectUrl: string;
        };
      }>
    >(HttpDriveApi.GetFullUrlByHash, { params: { urlHash } });
  }
}
