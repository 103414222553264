export enum ErrorTip {
  Required = 'This field is required.',
  Email = 'Email is not correct.',
  UnRegister = 'This email has not been registered yet',
  Code = "That code wasn't valid.",
  Existed = 'This email already exists.',
  MaxLength = 'Maximum number of characters is ',
  Color = 'Color is not correct.',
  VerificationCode = 'Verification code is incorrect.',
}
