import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { NgxWebstorageModule } from "ngx-webstorage";

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxWebstorageModule],
})
export class EmailModule {
  static injector: Injector;

  constructor(injector: Injector) {
    EmailModule.injector = injector;
  }
}
