export enum LpPlatformApi {
  GetConfiguration = '/invest/client/get-tenant-info',
  GetSupportLanguage = '/lp/tenant/i18n/configs',
  AddActivities = '/lp/activities/add',
  Tenant = '/tenant',
  GetLanguages = '/lp/tenant/i18n/configs/langs',
  TenantSetting = '/v2/lp/tenant-setting',
  TenantSettings = '/v2/lp/tenant-settings',
}

export enum GpPlatformApi {
  tenantSetting = '/v2/gp/tenant-settings',
}

export enum LpAccountApi {
  SetPassword = '/invest/account/set-password',
  UpdateAccount = '/user-center/update',
  // GetAccount = '/invest/account/get-by-id',
  GetAccount = '/user-center/get',
  Logout = '/invest/account/logout',
  QueryCurrency = '/currencies',
  CurrencyPolicy = '/currency-policy',
  EmailPreferences = '/email-preferences',
}

export enum ShareClassApi {
  getShareClasses = '/share-class/get',
}

export enum ProductApi {
  QueryProductList = '/product/v2.0/assets',
  GetProduct = '/product/get',
  GetProductContract = '/product/:productId/contract',

  // for chat conpoment
  // LP-Conversation Category Related assets List
  GetChatRelatedAssets = '/lp/chat/categories/related-assets?locations=:location',
  // LP-Conversation Category List
  GetChatAssetCategories = '/lp/chat/categories?locations=:location&preferredAsset=:preferredAsset',
}

export enum DataRoomApi {
  QueryFile = '/data-room-documents/query-watermark-file',
  QueryFolder = '/data-room-documents/query-folder',
  QueryComplianceDocuments = '/data-room-documents/compliance-documents',
  UploadPrivateFile = '/data-room-documents/upload-private-file',
  QuerySubscriptionDocuments = '/data-room-documents/subscription-documents',
  QueryFileWithoutWatermark = '/data-room-documents/query-file',
  DownloadDocuments = '/data-room-documents/:folderId/zip',
}

export enum MarketingDataRoomApi {
  QueryFile = '/products/:productId/documents/:documentId',
  QueryFolder = '/products/:productId/documents',
  DownloadDocuments = '/products/:productId/marketing-documents/:folderId/zip',
}

export enum ScenarioApi {
  GetScenarioByTempToken = '/biteflow/scenario/query-by-temp-token',
  SubmitForm = '/biteflow/scenario/gateway/form/submit',
  GetScenarioProgress = '/biteflow/scenario/progress/get',
  GetScenario = '/biteflow/scenario/get-full-scenario',
  GetNodeData = '/biteflow/scenario/gateway/node/get',
  SubmitNodeResult = '/biteflow/scenario/submit-node-result',
  SaveNodeResult = '/biteflow/scenario/node-results',
  DiscardInvest = '/biteflow/scenario/delete-node-result',
  DeleteNodeResult = '/biteflow/delete-scenario-node-result',
  GetGatewayContracts = '/biteflow/scenario/gateway/instance/gateway-contacts',
  previous = '/products/:productId/investing/previous',
  QueryProductCustodianStatus = '/products/:productId/mt-custodian',
}

export enum ScenarioCustomFieldApi {
  SyncCustomField = '/gateway/custom-field',
}

export enum SurveyApi {
  GetQuestion = '/surveys/questions/detail',
  SubmitAnswer = '/surveys/questions/action/submit',
  ClearAnswer = '/surveys/questions/action/clear',
}

export enum MetaApi {
  QueryCountries = '/meta-country/query',
}

export enum SubscriptionProfileApi {
  GetProfileInfo = '/subscription/profile/get-profile-info',
  GetProfileList = '/subscription/profile/list-profile-info',
  DeleteProfile = '/subscription/profile/delete-profile',
  PatchProfile = '/subscription/profile/patch-profile',
  GetIndividualMetaInfo = '/subscription/profile/get-individual-meta-info',
  PatchIndividual = '/subscription/profile/patch-individual-meta-info',
  GetAddressList = '/subscription/profile/get-meta-address-info-by-type',
  PatchAddress = '/subscription/profile/patch-meta-address-info',
  GetBank = '/subscription/profile/get-meta-bank-info-by-type',
  GetIRA = '/subscription/profile/get-meta-mt-custodian-data-by-type',
  PatchIRA = '/v1/mt/account-link',
  GetMTDocumentList = '/v1/mt/documents',
  DownloadMTDocumentFile = '/v1/mt/documents/:fileId/download',
  PatchBank = '/subscription/profile/patch-meta-bank-info',
  GetIdentity = '/subscription/profile/get-meta-certificate-info-by-type',
  PatchIdentity = '/subscription/profile/patch-meta-certificate-info',
  GetMembers = '/subscription/profile/list-meta-members-info',
  PatchMember = '/subscription/profile/patch-meta-members-info',
  DeleteMember = '/subscription/profile/delete-entity-member',
  GetPersons = '/subscription/profile/list-entity-member-selection-people',
  GetTenantAttribute = '/tenants',
  GetInvestorCalls = '/investor-calls',
  GetTransactions = '/transactions',
  GetAdditionalDocument = '/profiles/:profileId/documents',
  PostAdditionalDocument = '/profiles/:profileId/documents/:documentId',
  AddNewMandatoryDocument = '/profiles/:profileId/documents',
  DeleteAdditionalDocument = '/profiles/:profileId/documents/:documentId',
}

export enum DashboardApi {
  GetNavLineGraph = '/dashboard/nav-line-graph',
  GetSubscriptionStatistics = '/dashboard/statistics-subscription',
  GetSubscriptionInProgress = '/dashboard/get-subscription-in-progress',
}
export enum InboxApi {
  TransferAmountIRA = '/v1/mt/investments',
  GetInboxMessage = '/user-center/get-in-box-message',
  GetInboxMessageUnreadCount = '/user-center/in-box-messages/unread-count',
  ReadInboxMessage = '/user-center/read-in-box-message',
  GetInboxBanner = '/user-center/banner-in-box-message',
  GetInboxMessageDetail = '/user-center/messages/:id',
}

export enum SignApi {
  GetPdfFileDetail = '/pdffile/detail',
  GetProjectDetail = '/project/detail',
  UpdateFile = '/project/file/update',
  StartUpProject = '/project/startup',
  SignCaptcha = '/sign/captcha',
  SignValidate = '/sign/validate',
  GetSealList = '/seal/list',
  SignProject = '/sign/project',
  GetProjectStatus = '/sign/project/status',
  ResendEmail = '/project/resend',
  SignDetail = '/sign/detail',
  DownloadFile = '/project/file/download',
  GetPdfFiles = '/pdf-files',
  UpdateFiles = '/project/files/update',
}

export enum SignerAPI {
  signer = '/signer/:signerId',
}

export enum SecurityApi {
  FactorApiPrefix = '/gp/security/factors',
  TenantAgreement = '/tenant-agreement',
  MFAAssistanceEmails = '/gp/account/mfa-assistance-emails',
}

export enum TaskApi {
  queryTasks = '/tasks/query-tasks',
  remindTasks = '/tasks/notice-task-approval',
  UpdateTaskSubscriptionFile = '/task-subscription-file',
  UpdateTasks = '/tasks/:id',
}

export enum PolicyApi {
  GetPolicy = '/policy',
  getBankCodePolicy = '/tenants/:tenantId/policies',
}

export enum TenantApi {
  GetTenantAttribute = '/tenants/:tenantId/attributes',
}

export enum ProfileApi {
  GetProfileInvestorDoc = '/profiles/:profileId/documents',
  UpdateProfileInvetorDoc = '/profiles/:profileId/documents/:documentsId',
}

export enum LandingApi {
  EmailPreferences = '/invest-account/email-preferences',
}

export enum MandatoryDocsApi {
  uploadMandatoryDoc = '/tenant/:tenantId/files',
  GetFundMandatoryDocs = '/tenant/:tenantId/fund-mandatory-docs',
}

export enum DocumentTagApi {
  getDocumentTags = '/v2/tenant/:tenantId/tags',
  getDocumentsByTagOrProfile = '/v2/tenant/:tenantId/profiles/documents',
  getTagsStatistics = '/v2/tags-statistics',
}

export enum PortalComponentsApi {
  getPortalComponents = '/v2/tenants/portal-components',
  getProductPortalComponents = '/products/:productId/portal-components?visibilityState=SHOW',
}

export enum ChatApi {
  getChatUnreadCount = '/lp/chat/unread-count',
  getTenantFeatures = '/features',
  getChatFeatures = '/lp/chat/features',
  getChatConversations = '/lp/chat/conversations',
  getChatCategories = '/lp/chat/categories',
  getSubscriptions = '/product-subscription/subscriptions',
  chatConversation = '/lp/chat/conversation',
  editChatConversationRecipients = '/lp/chat/conversation/:conversationId/recipients/:recipientId',
  getChatConversationRecipientsNotExists = '/lp/chat/conversation/recipients/not-exists',
  getChatConversationRecipientsExists = '/lp/chat/conversation/:conversationId/recipients/exists',
  chatConversationMessages = '/lp/chat/conversation/:conversationId/messages',
  chatConversationRecipientTime = '/lp/chat/conversation/:conversationId/recipient/time',
}

// ! tip: 仅用于修改投资流程
export enum LateSignApi {
  modification = '/subscription/:subscriptionId/modification',
  subscriptionSnapshot = '/subscription/:subscriptionId/snapshot-data',
  subscriptionConfirm = '/subscription/:subscriptionId/modification/confirm',
  snapshot = '/subscription/:subscriptionId/snapshot-data',
  submitSubscription = '/subscription/:subscriptionId/submitted-subscription',
  GetGatewayContractsv2 = '/biteflow/scenario/gateway/instance/gateway-contacts/v2',
  mandatoryDocs = '/subscription/:subscriptionId/fund-mandatory-docs',
  contractTags = '/v2/contract-tags',
  previous = '/subscription/:subscriptionId/modification/previous',
  discard = '/subscription/:subscriptionId/modification/discard',
  getNodeData = '/subscription/:subscriptionId/modification/node',
}

export enum UplaodApi {
  upload = '/v2/files',
  scan = '/v2/files/:fileName/scan-result',
}

export enum CrmApi {
  principalDetail = '/gp/crm/principal-detail',
}
