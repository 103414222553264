import { getRandomColor } from '@stream/utils';
import { DomainToken, DomainTokenGroup, WorkspaceToken } from '../model/auth.model';
import { Store } from './base.store';
import { GPSignInStoreKey, GPSignUpStoreKey } from './store-keys';

export class GPAuthStore extends Store {
  get domainToken() {
    return this.localStorage.retrieve(GPSignInStoreKey.DomainToken);
  }

  /**
   * @description 设置选定子域的 Token
   * @memberof GPAuthStore
   */
  set domainToken(domain: DomainToken | null) {
    if (!domain) {
      this.localStorage.clear(GPSignInStoreKey.DomainToken);
      return;
    }
    this.localStorage.store(GPSignInStoreKey.DomainToken, domain ?? '');
  }

  /** 所有已登陆的域信息 */
  get domainTokenGroup(): DomainTokenGroup[] {
    return this.localStorage.retrieve(GPSignInStoreKey.DomainTokenGroup) || [];
  }

  set domainTokenGroup(value: DomainTokenGroup[] | undefined) {
    this.localStorage.store(GPSignInStoreKey.DomainTokenGroup, value);
  }

  /**
   * 本地添加tokenGroup
   * @param email 邮件
   * @param domains 域信息
   */
  addDomainToken(email: string, domains: DomainToken[]) {
    this.domainTokenGroup = [
      {
        email,
        domains: domains?.map(item => ({ ...item, color: getRandomColor() }))
      }
    ];
  }

  clearDomainToken() {
    this.domainTokenGroup = [];
  }

  /** 本地临时domainToken */
  get tempDomainToken() {
    return this.localStorage.retrieve(GPSignInStoreKey.DomainToken) || null;
  }

  set tempDomainToken(value: DomainToken) {
    this.localStorage.store(GPSignInStoreKey.DomainToken, value);
  }

  get workspaceToken(): WorkspaceToken | null {
    return this.localStorage.retrieve(GPSignInStoreKey.DomainToken);
  }

  set workspaceToken(token: WorkspaceToken | null) {
    if (!token) {
      this.localStorage.clear(GPSignInStoreKey.DomainToken);
      return;
    }
    this.localStorage.store(GPSignInStoreKey.DomainToken, token);
  }

  addWorkspaceToken(params: { tokenInfo: WorkspaceToken }) {
    this.workspaceToken = {
      ...params.tokenInfo
    };
  }

  /** 注册使用的邮箱 */
  get signUpEmail() {
    return this.localStorage.retrieve(GPSignUpStoreKey.SignUpEmail);
  }

  set signUpEmail(value: string) {
    this.localStorage.store(GPSignUpStoreKey.SignUpEmail, value);
  }

  /**
   * @description 清空本地存储注册信息
   */
  clearSignUpStore() {
    this.localStorage.clear(GPSignUpStoreKey.TempToken);
    this.localStorage.clear(GPSignUpStoreKey.InviteCode);
    this.localStorage.clear(GPSignUpStoreKey.SignUpEmail);
    this.localStorage.clear(GPSignUpStoreKey.SendCodeTimer);
    this.localStorage.clear(GPSignUpStoreKey.CompanyName);
    this.localStorage.clear(GPSignUpStoreKey.DomainPrefix);
  }

  /**
   * @description 临时token
   */
  get tempToken() {
    return this.localStorage.retrieve(GPSignUpStoreKey.TempToken) || null;
  }

  set tempToken(value: string) {
    this.localStorage.store(GPSignUpStoreKey.TempToken, value);
  }

  /**
   * @description 邀请验证码
   */
  get inviteCode() {
    return this.localStorage.retrieve(GPSignUpStoreKey.InviteCode);
  }

  set inviteCode(value: string) {
    this.localStorage.store(GPSignUpStoreKey.InviteCode, value);
  }

  /** 当前子域AccessToken */
  get accessToken() {
    return this.workspaceToken?.accessToken ?? null;
  }

  /** 当前子域RefreshToken */
  get refreshToken() {
    return this.workspaceToken?.refreshToken ?? null;
  }

  /** 发出邀请的公司名 */
  get companyName() {
    return this.localStorage.retrieve(GPSignUpStoreKey.CompanyName) || 'STREAM';
  }

  set companyName(value: string) {
    this.localStorage.store(GPSignUpStoreKey.CompanyName, value);
  }

  /** 本地邀请临时Token */
  get inviteDomainToken() {
    return this.localStorage.retrieve(GPSignUpStoreKey.InviteDomainToken) || null;
  }

  set inviteDomainToken(value: DomainToken) {
    this.localStorage.store(GPSignUpStoreKey.InviteDomainToken, value);
  }

  /** domain */
  get domainPrefix() {
    return this.localStorage.retrieve(GPSignUpStoreKey.DomainPrefix) || '';
  }

  set domainPrefix(value: string) {
    this.localStorage.store(GPSignUpStoreKey.DomainPrefix, value);
  }
}
