import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import zh from '@angular/common/locales/zh';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { EmailModule } from '@stream/ngx-utils';
import { CountModule } from '@stream/helpers';
import {
  RECAPTCHA_BASE_URL,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaSettings,
  RecaptchaV3Module
} from 'ng-recaptcha';
import { en_US, NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NgxWebstorageModule } from 'ngx-webstorage';

import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from '@stream/libs/common/ngx-utils';

// eslint-disable-next-line camelcase
registerLocaleData(en);
registerLocaleData(zh);

const reCaptchaProviders = [
  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.reCaptchaKeyV3
  },
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: environment.reCaptchaKeyV2,
      size: 'invisible'
    } as RecaptchaSettings
  },
  {
    provide: RECAPTCHA_LANGUAGE,
    useFactory: (locale: string) => locale,
    deps: [LOCALE_ID]
  },
  {
    provide: RECAPTCHA_BASE_URL,
    useValue: 'https://recaptcha.net/recaptcha/api.js' // use recaptcha.net script source for some of our users
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    NgxWebstorageModule.forRoot({
      prefix: 'stream-gp-workspace'
    }),
    NzLayoutModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
    EmailModule,
    CountModule,
    LayoutModule,
    FormsModule,
    HttpClientModule,
    ToastModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {}, // eslint-disable-line
      deps: [TraceService],
      multi: true
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => {
        switch (localId) {
          case 'en':
            // eslint-disable-next-line camelcase
            return en_US;
          case 'zh':
            // eslint-disable-next-line camelcase
            return zh_CN;
          default:
            // eslint-disable-next-line camelcase
            return en_US;
        }
      },
      deps: [LOCALE_ID]
    },
    ...reCaptchaProviders
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
