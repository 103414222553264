import { enableProdMode } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserTracing, init, instrumentAngularRouting } from '@sentry/angular-ivy';
import { deployingEnvironment } from '@stream/utils';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'codemirror/mode/javascript/javascript';

const env = deployingEnvironment();
const IGNORE_ENVS = ['DEV1', 'SIT1', 'DEV-USA', 'ENGINEER'];

if (environment.production && !IGNORE_ENVS.includes(env)) {
  init({
    dsn: 'https://f759afa1ed77466b84a265d25f1154c6@o1100041.ingest.sentry.io/4504393148989440',
    ignoreErrors: ['Non-Error', 'ChunkLoadError'],
    environment: deployingEnvironment(),
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', /^\//],
        routingInstrumentation: instrumentAngularRouting
      })
    ],
    tracesSampleRate: 0.3
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  // .then(() => console.log(`Bootstrap success`))
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));

const original = DefaultValueAccessor.prototype.registerOnChange;

DefaultValueAccessor.prototype.registerOnChange = function (fn) {
  return original.call(this, value => fn(typeof value === 'string' ? value.trim() : value));
};
