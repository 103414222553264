import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '../shared/shared.module';
import { VerifyCodeInputComponent } from './components/verify-code-input/verify-code-input.component';
import { httpInterceptorProviders } from './http';

@NgModule({
  declarations: [VerifyCodeInputComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
  ],
  exports: [VerifyCodeInputComponent],
  providers: [httpInterceptorProviders],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('重复加载CoreModule');
    }
  }
}
