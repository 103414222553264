const DeployingEnvironmentMap: Record<string, string> = {
  // DEV SIT & PRE
  'dev1.stream.bite.dev': 'DEV1',
  'engr.bite.dev': 'ENGINEER',
  'sit1.stream.bite.dev': 'SIT1',
  'usa-dev.stream.bite.dev': 'DEV-USA',
  'pre.stream.bite.dev': 'PRE',

  // EU
  'eu-pre.bitestream.co': 'PRE-EU',
  'eu-pre-blue-cluster.bitestream.co': 'PRE-EU-BLUE',
  'eu-pre-green-cluster.bitestream.co': 'PRE-EU-GREEN',

  'eu.bitestream.co': 'PROD-EU',
  'eu-blue-cluster.bitestream.co': 'PROD-EU-BLUE',
  'eu-green-cluster.bitestream.co': 'PROD-EU-GREEN',
  'eu-sand.bitestream.co': 'SAND-EU',
  'eu-sand-blue-cluster.bitestream.co': 'SAND-EU-BLUE',
  'eu-sand-green-cluster.bitestream.co': 'SAND-EU-GREEN',

  // US
  'us-pre.bitestream.co': 'PRE-US',
  'us-pre-blue-cluster.bitestream.co': 'PRE-US-BLUE',
  'us-pre-green-cluster.bitestream.co': 'PRE-US-GREEN',

  'us.bitestream.co': 'PROD-US',
  'us-blue-cluster.bitestream.co': 'PROD-US-BLUE',
  'us-green-cluster.bitestream.co': 'PROD-US-GREEN',
  'us-sand.bitestream.co': 'SAND-US',
  'us-sand-blue-cluster.bitestream.co': 'SAND-US-BLUE',
  'us-sand-green-cluster.bitestream.co': 'SAND-US-GREEN',

  // APAC
  'ap-pre.bitestream.co': 'PRE-APAC',
  'ap-pre-blue-cluster.bitestream.co': 'PRE-APAC-BLUE',
  'ap-pre-green-cluster.bitestream.co': 'PRE-APAC-GREEN',

  'ap.bitestream.co': 'PROD-APAC',
  'ap-blue-cluster.bitestream.co': 'PROD-APAC-BLUE',
  'ap-green-cluster.bitestream.co': 'PROD-APAC-GREEN',
  'ap-sand.bitestream.co': 'SAND-APAC',
  'ap-sand-blue-cluster.bitestream.co': 'SAND-APAC-BLUE',
  'ap-sand-green-cluster.bitestream.co': 'SAND-APAC-GREEN'
};

/**
 * Handle system deploy environment
 * @returns string
 */
export const deployingEnvironment = (): string => {
  const hostname = window.location.hostname;

  for (const env in DeployingEnvironmentMap) {
    if (hostname.indexOf(env) > -1) {
      return DeployingEnvironmentMap[env];
    }
  }

  return 'UNKNOWN';
};

export const loadGoogleAnalytics = (trackingID: string) => {
  const gaScript = document.createElement('script');
  gaScript.setAttribute('async', 'true');
  gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);

  const gaScript2 = document.createElement('script');
  gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${trackingID}');`;
  if (document && document.documentElement && document.documentElement.firstChild) {
    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }
};
