import {
  FundamentalLiquidityEnum,
  FundamentalStatusEnum,
  FundamentalStyleEnum,
  FundamentalTargetReturnEnum,
  FundamentalTargetReturnType,
  FundamentalTargetReturnV2Enum,
  GeographicFocusEnum,
  StrategiesEnum
} from '../interfaces';

export const STRATEGY_MAP = new Map(
  Object.entries(StrategiesEnum).map(([key, value]) => [value, key])
);

export const STYLE_MAP = new Map([
  [FundamentalStyleEnum.CapitalGrowth, 'Capital Growth'],
  [FundamentalStyleEnum.Income, 'Income'],
  [FundamentalStyleEnum.Discretionary_Macro, 'Discretionary Macro'],
  [FundamentalStyleEnum.Absolute_Return, 'Absolute Return'],
  [FundamentalStyleEnum.Fine_Art, 'Fine Art'],
  [FundamentalStyleEnum.Impact, 'Impact'],
  [FundamentalStyleEnum.ArtAndValuablesCredit, 'Art and Valuables Credit'],
  [FundamentalStyleEnum.FundOfFunds, 'Fund of funds'],
  [FundamentalStyleEnum.DIRECT_INVESTMENT, 'Direct Investment'],
  [FundamentalStyleEnum.CapitalProtected, 'Capital Protected'],
  [FundamentalStyleEnum.ASSET_ALLOCATION, 'Asset Allocation']
]);

export const LIQUIDITY_MAP = new Map([
  [FundamentalLiquidityEnum.Monthly, 'Monthly'],
  [FundamentalLiquidityEnum.Quarterly, 'Quarterly'],
  [FundamentalLiquidityEnum.Yearly, 'Yearly'],
  [FundamentalLiquidityEnum.ThreeToFiveYears, '3-5 Years'],
  [FundamentalLiquidityEnum.MoreThanFiveYears, '5+ Years']
]);

export const GEOGRAPHIC_FOCUS_MAP = new Map([
  [GeographicFocusEnum.ASIA, 'Asia'],
  [GeographicFocusEnum.Europe, 'Europe'],
  [GeographicFocusEnum.Global, 'Global'],
  [GeographicFocusEnum.INDIA, 'India'],
  [GeographicFocusEnum.USA, 'USA'],
  [GeographicFocusEnum.EmergingMarkets, 'Emerging markets'],
  [GeographicFocusEnum.Africa, 'Africa'],
  [GeographicFocusEnum.LATAM, 'LATAM'],
  [GeographicFocusEnum.Brazil, 'Brazil'],
  [GeographicFocusEnum.MENA, 'MENA'],
  [GeographicFocusEnum.Australia, 'Australia']
]);

/**
 * @deprecated use target_return_v2_map
 */
export const TARGET_RETURN_MAP = new Map([
  [FundamentalTargetReturnEnum.EMPTY, '-'],
  [FundamentalTargetReturnEnum.LT5, '< 5%'],
  [FundamentalTargetReturnEnum.EQ5, '5%'],
  [FundamentalTargetReturnEnum.EQ5TO10, '5%-10%'],
  [FundamentalTargetReturnEnum.EQ5TO6, '5%-6%'],
  [FundamentalTargetReturnEnum.EQ6P5, '6.5%'],
  [FundamentalTargetReturnEnum.EQ6TO7, '6%-7%'],
  [FundamentalTargetReturnEnum.EQ7TO8, '7%-8%'],
  [FundamentalTargetReturnEnum.EQ8, '8%'],
  [FundamentalTargetReturnEnum.EQ8TO9, '8%-9%'],
  [FundamentalTargetReturnEnum.EQ9, '9%'],
  [FundamentalTargetReturnEnum.EQ9TO10, '9%-10%'],
  [FundamentalTargetReturnEnum.EQ10, '10%'],
  [FundamentalTargetReturnEnum.EQ10P5, '10.5%'],
  [FundamentalTargetReturnEnum.EQ11, '11%'],
  [FundamentalTargetReturnEnum.EQ10TO11, '10%-11%'],
  [FundamentalTargetReturnEnum.EQ10TO12, '10%-12%'],
  [FundamentalTargetReturnEnum.EQ11P5, '11.5%'],
  [FundamentalTargetReturnEnum.EQ11TO12, '11%-12%'],
  [FundamentalTargetReturnEnum.EQ12, '12%'],
  [FundamentalTargetReturnEnum.EQ12P5, '12.5%'],
  [FundamentalTargetReturnEnum.EQ11TO13, '11%-13%'],
  [FundamentalTargetReturnEnum.EQ12TO13, '12%-13%'],
  [FundamentalTargetReturnEnum.EQ13, '13%'],
  [FundamentalTargetReturnEnum.EQ12TO14, '12%-14%'],
  [FundamentalTargetReturnEnum.EQ12TO15, '12%-15%'],
  [FundamentalTargetReturnEnum.EQ13TO14, '13%-14%'],
  [FundamentalTargetReturnEnum.EQ14, '14%'],
  [FundamentalTargetReturnEnum.EQ14TO15, '14%-15%'],
  [FundamentalTargetReturnEnum.EQ10TO15, '10%-15%'],
  [FundamentalTargetReturnEnum.EQ15, '15%'],
  [FundamentalTargetReturnEnum.EQ16, '16%'],
  [FundamentalTargetReturnEnum.EQ17, '17%'],
  [FundamentalTargetReturnEnum.EQ15TO17, '15%-17%'],
  [FundamentalTargetReturnEnum.EQ15TO18, '15%-18%'],
  [FundamentalTargetReturnEnum.EQ18, '18%'],
  [FundamentalTargetReturnEnum.EQ19, '19%'],
  [FundamentalTargetReturnEnum.EQ15TO20, '15%-20%'],
  [FundamentalTargetReturnEnum.EQ20, '20%'],
  [FundamentalTargetReturnEnum.EQ20TO30, '20%-30%'],
  [FundamentalTargetReturnEnum.GT20, '> 20%'],
  [FundamentalTargetReturnEnum.EQ30, '30%'],
  [FundamentalTargetReturnEnum.EQ30TO40, '30%-40%'],
  [FundamentalTargetReturnEnum.GT40, '> 40%'],
  [FundamentalTargetReturnEnum.NET_IRR_20, '20% Net IRR'],
  [FundamentalTargetReturnEnum.ABSOLUTE_RETURN, 'Absolute return']
]);

export const TARGET_RETURN_V2_MAP = new Map([
  [FundamentalTargetReturnV2Enum.EQ0TO5, '0-5%'],
  [FundamentalTargetReturnV2Enum.EQ5TO10, '5-10%'],
  [FundamentalTargetReturnV2Enum.EQ10TO15, '10-15%'],
  [FundamentalTargetReturnV2Enum.EQ15TO20, '15-20%'],
  [FundamentalTargetReturnV2Enum.EQ20TO30, '20-30%'],
  [FundamentalTargetReturnV2Enum.EQ30TO40, '30-40%'],
  [FundamentalTargetReturnV2Enum.GT40, '>40%'],
  [FundamentalTargetReturnV2Enum.OTHER, 'Other']
]);

export const PRODUCT_STATUS_MAP = new Map([
  [FundamentalStatusEnum.Closed, 'Closed'],
  [FundamentalStatusEnum.Open, 'Open'],
  [FundamentalStatusEnum.ComingSoon, 'Coming soon'],
  [FundamentalStatusEnum.ClosingSoon, 'Closing soon']
]);

export const PRODUCT_TARGET_RETURN_TYPE_LABEL = {
  [FundamentalTargetReturnType.range]: 'Range',
  [FundamentalTargetReturnType.flat]: 'Flat',
  [FundamentalTargetReturnType.other]: 'Other'
};
