import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthType } from '@stream/models';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { SendEmail } from '@stream/ngx-utils';
import { HttpAccountApi, HttpApi, HttpSignInApi, HttpStaffApi } from '../http/http-api';
import { CheckResetToken, SetPasswordStatus } from '../model/account.model';
import {
  DomainToken,
  GpLoginStatusEnum,
  InviteCodeStatus,
  RefreshTokenRes,
  SaveAccountInfo,
  SignInDTO,
  SignInPayload,
  SignUpInfo,
  WorkspaceToken
} from '../model/auth.model';
import { AuthStore } from '../store/auth.store';
import { CommonStoreKey, SignInStoreKey } from '../store/store-keys';
import { environment } from './../../../environments/environment';
import { getRootHostDomain } from './../../../utils/domain.util';
import { GpAuthService } from './gp-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private gpAuthService: GpAuthService
  ) {}

  public authStore = new AuthStore(this.localStorage);
  public loginSubject = new Subject<void>();

  /**
   * @description 刷新 token
   * @returns 新的 accessToken
   */
  refreshToken() {
    return this.http
      .get<Rest<RefreshTokenRes>>(HttpSignInApi.RefreshToken, {
        headers: {
          RefreshToken: this.authStore.refreshToken ?? ''
        }
      })
      .pipe(
        tap(({ data }) => {
          if (data?.tokenResponse) {
            const { accessToken, refreshToken } = data.tokenResponse;
            this.authStore.addWorkspaceToken({ tokenInfo: data.tokenResponse });
            this.loginSubject.next();
            this.authStore.domainToken = {
              ...this.authStore.domainToken,
              accessToken,
              refreshToken
            } as DomainToken;
          }
        }),
        map(({ data }) => data?.tokenResponse?.accessToken)
      );
  }

  /**
   * @description 注册邮箱存在性检查
   */
  isMailExist(email: string): Observable<Rest<boolean>> {
    return this.http.get<Rest<boolean>>(HttpAccountApi.CheckEmailValid, {
      params: { email, code: this.authStore.inviteCode }
    });
  }

  /**
   * @description 发送验证邮件
   */
  sendVerifyMail(email: string) {
    this.authStore.signUpEmail = email;
    return this.http.get<Rest<boolean>>(HttpAccountApi.SendVerifyMail, {
      params: { email }
    });
  }

  /**
   * @description 验证邮箱验证码
   */
  verifyMailCode(email: string, code: string) {
    return this.http
      .get<
        Rest<{
          temporaryToken: string;
          verifyResult: boolean;
        }>
      >(HttpAccountApi.VerifyMailCode, {
        params: { email, code }
      })
      .pipe(
        tap(({ data: { temporaryToken, verifyResult } }) => {
          if (verifyResult) {
            this.authStore.tempToken = temporaryToken;
            this.authStore.signUpEmail = email;
          }
        })
      );
  }

  /**
   * @description 保存GP用户信息
   */
  saveAccountInfo(info: Pick<SignUpInfo, 'companyName' | 'domainPrefix' | 'userName'>) {
    return this.http
      .post<Rest<SaveAccountInfo>>(HttpAccountApi.SaveAccountInfo, {
        ...info,
        email: this.authStore.signUpEmail,
        temporaryToken: this.authStore.tempToken
      })
      .pipe(
        tap(({ data: { gpLoginInfo, gpAccountSaveStatusEnum, tokenInfo } }) => {
          if (gpAccountSaveStatusEnum === 'failed') {
            return;
          }
          this.gpAuthService.gpAuthStore.tempDomainToken = gpLoginInfo?.find(
            ({ prefixValue }) => prefixValue === info.domainPrefix
          )!;
          this.gpAuthService.gpAuthStore.inviteDomainToken = gpLoginInfo?.find(
            ({ prefixValue }) => prefixValue === info.domainPrefix
          )!;
          this.gpAuthService.gpAuthStore.companyName = info.companyName;
          this.gpAuthService.gpAuthStore.domainPrefix = info.domainPrefix;
          this.gpAuthService.gpAuthStore.addDomainToken(this.authStore.signUpEmail, gpLoginInfo);
          this.gpAuthService.gpAuthStore.addWorkspaceToken({
            tokenInfo
          });
        })
      );
  }

  /**
   * @description 生成员工邀请链接
   * @deprecated 功能弃用
   */
  getShareableLink() {
    return this.http.get<Rest<{ shareableLink: string }>>(HttpStaffApi.GetShareableLink, {
      headers: {
        AccessToken: this.authStore.inviteDomainToken?.accessToken || ''
      }
    });
  }

  /**
   * @description 发送邀请邮件
   */
  sendInviteEmail(emails: string[], rolesType: string[] | null = []) {
    return this.http.post<Rest<{ gpMailSentEnum: string; gpMailSentMessage: string }>>(
      HttpStaffApi.SendInviteEmail,
      { emails, rolesType },
      {
        headers: {
          AccessToken: this.authStore.inviteDomainToken?.accessToken || ''
        }
      }
    );
  }

  /**
   * @description 域名前缀存在性检查
   */
  isDomainExist(prefix: string) {
    return this.http.post<Rest<{ domainIsExist: boolean; domainPrefixSuggestion: any }>>(
      HttpApi.IsDomainExist,
      {
        isReturnDomainPrefixSuggestion: true,
        prefix
      }
    );
  }

  /**
   * @description 验证邮箱链接
   * @param hashLink 邮箱链接携带的code
   */
  verifyMailLink(hashLink: string) {
    return this.http
      .get<Rest<{ email: string; temporaryToken: string; verifyResult: boolean }>>(
        HttpAccountApi.VerifyMailLink,
        { params: { hashLink } }
      )
      .pipe(
        tap(({ data: { verifyResult, temporaryToken, email } }) => {
          if (verifyResult) {
            this.authStore.tempToken = temporaryToken;
            this.authStore.signUpEmail = email;
          }
        })
      );
  }

  /**
   * @description 注册流程保存密码，将使用本地 storage 的 token
   * @param password 密码
   */
  setPassword(password: string) {
    return this.http.post<Rest<{ status: SetPasswordStatus }>>(
      HttpAccountApi.SetPassword,
      {
        password
      },
      {
        headers: {
          AccessToken: this.authStore.tempDomainToken.accessToken
        }
      }
    );
  }

  /**
   * @description 跳过设置密码
   */
  skipSetPassword() {
    return this.http.post<boolean>(
      HttpAccountApi.SetPasswordSkip,
      {
        gpAccountPasswordIsskip: true
      },
      {
        headers: {
          AccessToken: this.authStore.tempDomainToken.accessToken
        }
      }
    );
  }

  /**
   * @description Login Check / 登录邮箱预校验
   * @param email 当前邮件
   */
  loginCheck(email: string) {
    return this.http.get<Rest<{ gpAccountStatusEnum: string; isSetPassword: boolean }>>(
      HttpSignInApi.LoginCheck,
      {
        params: { email: encodeURIComponent(email) }
      }
    );
  }

  /**
   * @description 发送登录邮件
   */
  sendSignInVerifyMail(email: string) {
    return this.http.get<Rest<{ gpMailSentEnum: string; gpMailSentMessage: string }>>(
      HttpSignInApi.SendLoginMail,
      {
        params: { email }
      }
    );
  }

  /**
   * @description 登录
   */
  login(email: string, loginCode: string) {
    return this.http
      .post<Rest<SignInDTO>>(HttpSignInApi.LoginWithCode, {
        email,
        logincode: loginCode
      })
      .pipe(
        tap(({ data: { gpLoginStatusEnum, gpLoginInfo, tokenInfo } }) => {
          if (gpLoginStatusEnum === GpLoginStatusEnum.Success) {
            this.authStore.addWorkspaceToken({ tokenInfo });
            this.authStore.addDomainToken(email, gpLoginInfo);
            this.loginSubject.next();
          }
        })
      );
  }

  /**
   * @description 保存员工信息
   * @param staffInfo 员工信息
   */
  saveStaff(userName: string) {
    const email = this.authStore.signUpEmail;
    return this.http
      .post<
        Rest<{
          message: string;
          saveStatus: boolean;
          gpLoginInfo: DomainToken[];
          prefixValue: string;
          tokenInfo: WorkspaceToken;
        }>
      >(HttpStaffApi.SaveStaff, {
        userName,
        email,
        tempToken: this.authStore.tempToken,
        inviteLinkCode: this.authStore.inviteCode
      })
      .pipe(
        tap(({ data: { gpLoginInfo, saveStatus, prefixValue, tokenInfo } }) => {
          if (saveStatus) {
            const domainToken = gpLoginInfo.find(
              ({ prefixValue: prefixValueFinder }) => prefixValue === prefixValueFinder
            )!;
            this.authStore.tempDomainToken = domainToken;
            this.authStore.addWorkspaceToken({ tokenInfo });
            this.authStore.addDomainToken(email, gpLoginInfo);
            this.loginSubject.next();
          }
        })
      );
  }

  loginWithPassword(params: SignInPayload) {
    return this.http.post<Rest<SignInDTO>>(HttpSignInApi.LoginWithPassword, params).pipe(
      tap(({ data: { gpLoginStatusEnum, gpLoginInfo, tokenInfo } }) => {
        if (gpLoginStatusEnum === GpLoginStatusEnum.Success) {
          this.authStore.addWorkspaceToken({
            tokenInfo
          });
          this.authStore.addDomainToken(params.email, gpLoginInfo);
          this.loginSubject.next();
        }
      }),
      catchError(err => {
        if (err?.error?.code === 'B-00035') {
          this.router.navigate(['/domain-not-operational']);
        }
        return throwError(err);
      })
    );
  }

  verifyLoginMailLink(link: string) {
    return this.http
      .get<Rest<SignInDTO>>(HttpSignInApi.VerifyLoginMailLink, {
        params: { link }
      })
      .pipe(
        tap(({ data: { gpLoginStatusEnum, gpLoginInfo, tokenInfo } }) => {
          if (gpLoginStatusEnum === GpLoginStatusEnum.Success) {
            if (gpLoginInfo && gpLoginInfo.length > 0 && tokenInfo) {
              this.authStore.addWorkspaceToken({
                tokenInfo
              });
              this.authStore.addDomainToken(gpLoginInfo[0].gpAccountEmail, gpLoginInfo);
              this.loginSubject.next();
            }
          }
        })
      );
  }

  /**
   * @description 跳转到工作台
   * @param domain { tokenId: string; prefixValue: string }
   */
  goToDashboard(domain: DomainToken = this.authStore.tempDomainToken) {
    let url = '';
    const hostDomain = getRootHostDomain();
    const { onlineRootDomain, production } = environment;
    const { origin, protocol, host } = window.location;
    const { prefixValue, tokenId } = domain;
    const isLocalHost = hostDomain.indexOf('localhost') > -1;
    const isOnlineRootDomain = host.indexOf(onlineRootDomain) > -1;
    const returnUrl = this.route.snapshot.queryParams['returnUrl'];
    const urlSuffix = this.getGPLandingPageUrl(tokenId, returnUrl);

    if (production || isOnlineRootDomain) {
      url = Location.joinWithSlash(`${protocol}//${prefixValue}${hostDomain}/gp`, urlSuffix);
    } else if (isLocalHost) {
      url = Location.joinWithSlash(origin, urlSuffix);
      this.localStorage.store(SignInStoreKey.DomainToken, domain);
    } else {
      url = Location.joinWithSlash(`${origin}/gp`, urlSuffix);
    }
    this.authStore.clearSignUpStore();
    window.location.replace(url);
  }

  getGPLandingPageUrl(sid: string, returnUrl?: string) {
    let url = '/dashboard';
    if (!returnUrl) {
      return `${url}?sid=${sid}`;
    }
    if (/\?.+=.*/.test(returnUrl)) {
      return `${returnUrl}&sid=${sid}`;
    }
    return `${returnUrl}?sid=${sid}`;
  }

  goToRootWorkspace() {
    this.router.navigate(['/']);
  }

  goToLogin() {
    this.router.navigate(['/auth/sign-in']);
  }

  /**
   * @description 校验邮箱跳转链接
   */
  verifyInviteCode(code: string, tempToken: string) {
    return this.http.get<
      Rest<{
        status: InviteCodeStatus;
        message: string;
        mfaTicketForActive: string;
        passwordExists: boolean;
      }>
    >(HttpStaffApi.VerifyInviteCode, { params: { code, tempToken } });
  }

  session(
    params: {
      authType: AuthType;
      mfaCode?: string;
      mfaType?: string;
      mfaTicket: string;
      validatorLinkCode?: string;
    },
    email: string
  ) {
    return this.http.post<Rest<SignInDTO>>(HttpSignInApi.Session, params).pipe(
      tap(({ data: { gpLoginStatusEnum, gpLoginInfo, tokenInfo } }) => {
        if (gpLoginStatusEnum === GpLoginStatusEnum.Success) {
          this.authStore.addWorkspaceToken({ tokenInfo });
          this.authStore.addDomainToken(email, gpLoginInfo);
          this.loginSubject.next();
        }
      })
    );
  }

  goToSaasSignIn() {
    // 本地环境调试
    if (environment.production) {
      window.open(`${location.origin}/workspace/auth/sign-in`, '_self');
    } else {
      window.open(`${location.origin}/auth/sign-in`, '_self');
    }
  }

  /**
   * 设置密码
   * @param tempToken
   * @param password
   */
  resetPassword(tempToken: string, password: string) {
    return this.http.post<Rest<{ status: SetPasswordStatus }>>(HttpAccountApi.ResetPassword, {
      tempToken,
      password
    });
  }

  /**
   * 发送重置密码邮件
   * @param email
   * @param redirectUrl 重置完密码后跳转地址
   * @param landPageUrl 邮件中的链接地址
   * @param tenantId 租户ID
   */
  @SendEmail(CommonStoreKey.ForgotPassword)
  sendForgotPasswordMail(
    email: string,
    redirectUrl: string,
    landPageUrl: string,
    tenantId?: string
  ) {
    return this.http.post<Rest>(HttpAccountApi.SendResetMail, {
      email,
      redirectUrl,
      landPageUrl,
      tenantId
    });
  }

  /**
   * 重置密码的落地页，调用检查token是否效接口
   * @param token
   */
  checkResetToken(token: string) {
    return this.http.get<Rest<CheckResetToken>>(HttpAccountApi.CheckResetToken, {
      params: {
        token
      }
    });
  }
}
