import { Component, Input, signal } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'stream-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.less']
})
export class CopyButtonComponent {
  @Input()
  content!: string;

  hasCopied = signal(false);

  constructor(
    private clipboardService: ClipboardService,
    private nzMessageService: NzMessageService
  ) {}

  handleCopy() {
    if (!this.content || this.hasCopied()) {
      return;
    }
    this.clipboardService.copyFromContent(this.content);
    this.nzMessageService.success('Copied successfully', {
      nzDuration: 2000
    });
    this.hasCopied.set(true);
    setTimeout(() => {
      this.hasCopied.set(false);
    }, 2000);
  }
}
