import {
  ActiveAccountStatusEnum,
  ChangePasswordStatusEnum,
  EditEmailEnum,
  LoginStatusEnum,
  RegisterStatusEnum,
  SendRegisterEmailStatusEnum,
  VerifyActiveAccountStatusEnum,
  VerifyForgetPasswordStatusEnum,
} from '../status.enum';

export const REGISTER_ERROR_MESSAGE = new Map([
  [SendRegisterEmailStatusEnum.AccountNotExist, 'Account does not exist'],
  [SendRegisterEmailStatusEnum.AccountExist, 'Email already in use'],
  [SendRegisterEmailStatusEnum.DomainNotExist, 'The specified domain does not exist'],
  [SendRegisterEmailStatusEnum.EmailInstanceNotExist, 'Email instance does not exist'],
  [SendRegisterEmailStatusEnum.Failed, 'Email delivery failed'],
  [SendRegisterEmailStatusEnum.NotAutonomous, 'Non-autonomous domain'],
  [SendRegisterEmailStatusEnum.TenantNotExist, 'Tenant does not exist'],
]);

export const LOGIN_ERROR_MESSAGE = new Map([
  [LoginStatusEnum.AccountNotExist, 'Account does not exist'],
  [LoginStatusEnum.EmailNotExist, 'Email does not exist'],
  [LoginStatusEnum.PasswordError, 'Wrong password'],
  [LoginStatusEnum.TenantNotExist, 'Tenant does not exist'],
  [LoginStatusEnum.PrincipalIsLocked, 'Sorry, your account has been locked'],
  [LoginStatusEnum.ReCaptchaVerifyFailed, 'you are a robot!'],
  [LoginStatusEnum.AccountOrPasswordError, 'Email or password is incorrect.'],
  [LoginStatusEnum.FieldIsRequired ,'This field is required.'],
  [LoginStatusEnum.AccountLockoutPrompt, 'This is your 4th unsuccessful login attempt. Please note that if the 5th attempt fails, your account will be locked for 15 minutes.'],
  [LoginStatusEnum.AccountLockout, 'For security purposes, your account has been locked for 15 minutes due to five consecutive incorrect password attempts.'],
  [LoginStatusEnum.VerifyPasswordError, 'Password is incorrect.'],
]);

export const EMAIL_ERROR_MESSAGE = new Map<string | null, string>([
  [VerifyForgetPasswordStatusEnum.TempTokenError, 'The password reset link has expired.'],
  [RegisterStatusEnum.AccountIsVerified, 'This link has already been activated.'],
  [VerifyActiveAccountStatusEnum.TempTokenExpired, 'The active link has expired.'],
  [
    ActiveAccountStatusEnum.EmailHasBeenRegistered,
    'Your account has already been activated.',
  ],
]);

export const ACCOUNT_ERROR_MESSAGE = new Map([
  [
    ChangePasswordStatusEnum.CurrentPasswordFailed,
    'the current password you entered is incorrect.',
  ],
  // [ChangePasswordStatusEnum.Failed, 'Failed'],//类似token失效会报这个错，需要忽略
]);

export const INVITATION_ERROR_MESSAGE = new Map([
  [EditEmailEnum.EmailHasBeenRegistered, 'The email has been registered.'],
  [EditEmailEnum.Failed, 'Failed'],
]);
