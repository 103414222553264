import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppInactivityReminderComponent } from '@stream/inactivity-reminder';
import { SharedModule } from './../shared/shared.module';
import { WorkspaceNavComponent } from './components';
import { LayoutComponent } from './layout.component';
import {
  TermsOfUseComponent,
  DomainNotOperationalComponent,
  PrivacyPolicyComponent,
  WithoutPermissionComponent,
  NotFoundComponent,
  NoWorkspacesComponent
} from './pages';

@NgModule({
  declarations: [
    LayoutComponent,
    WorkspaceNavComponent,
    NoWorkspacesComponent,
    NotFoundComponent,
    WithoutPermissionComponent,
    AppInactivityReminderComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    DomainNotOperationalComponent
  ],
  imports: [SharedModule, RouterModule, ScrollingModule],
  exports: [
    LayoutComponent,
    NoWorkspacesComponent,
    NotFoundComponent,
    WithoutPermissionComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    DomainNotOperationalComponent
  ]
})
export class LayoutModule {}
