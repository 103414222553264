<ng-container *ngFor="let menu of menuList">
  <ng-container
    *ngTemplateOutlet="menuTemplate; context: { menu: this.menu }"
  ></ng-container>
</ng-container>

<ng-template #menuTemplate let-menu="menu">
  <a
    *ngIf="menu.path || menu.path === ''"
    class="route-item"
    [routerLink]="menu.path"
    routerLinkActive="route-active"
  >
    <em class="menu-icon" nz-icon [nzIconfont]="menu.icon"></em>
    {{ menu.label }}
  </a>
  <ng-container *ngIf="menu.children && !menu.path">
    <a
      class="route-item"
      (click)="toggleMenu(menu)"
      [class.route-active]="getRouteActive(menu)"
    >
      <em class="menu-icon" nz-icon [nzIconfont]="menu.icon"></em>
      {{ menu.label }}
    </a>
    <div
      class="sub-menu-list ml-8"
      [class.isOpen]="menu.isOpen"
      [style]="{
        height: getMenuHeight(menu)
      }"
    >
      <ng-container *ngFor="let item of menu.children">
        <ng-container
          *ngTemplateOutlet="menuTemplate; context: { menu: item }"
          [routerLink]="item.path"
        ></ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
