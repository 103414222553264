import { Component } from '@angular/core';

@Component({
  selector: 'stream-domain-not-operational',
  templateUrl: './domain-not-operational.component.html',
  styleUrls: ['./domain-not-operational.component.less']
})
export class DomainNotOperationalComponent {
  constructor() {}
}
