import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';

import { BiteValidators } from './../../../../utils/validators';

@Component({
  selector: 'stream-multi-email-input',
  templateUrl: './multi-email-input.component.html',
  styleUrls: ['./multi-email-input.component.less']
})
export class MultiEmailInputComponent implements AfterViewInit {
  emailLengthBefore = 0;

  @Input()
  formArray: FormArray = this.fb.array([]);

  @Input()
  placeholder = 'input email';

  @Input()
  formError = false;

  @ViewChild('input')
  inputEle?: ElementRef<any>;

  @Output()
  valueChange = new EventEmitter();

  @ViewChildren('tag')
  tags?: QueryList<any>;

  inputControl = this.fb.control('');

  constructor(private fb: FormBuilder) {}
  ngAfterViewInit(): void {
    // 监听tag列表渲染变化如果新增滚动到底部
    this.tags?.changes.subscribe(t => {
      if (t.length > this.emailLengthBefore) {
        this.inputEle?.nativeElement.scrollIntoView({
          block: 'end'
        });
      }
      this.emailLengthBefore = t.length;
    });
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.addEmail();
    }
  }

  addEmail() {
    if (this.inputControl.value) {
      this.formArray.push(this.fb.control(this.inputControl.value.trim(), [BiteValidators.email]));
    }
    this.inputControl.setValue('');
  }
}
