<div class="flex h-full min-h-screen w-full bg-[#F5F6FA]">
  <div class="left-side" *ngIf="!menuHide">
    <div class="logo-wrapper">
      <img src="./assets/images/logo.svg" alt="bite-stream" />
    </div>
    <nav class="menu-wrapper">
      <stream-workspace-nav [menuList]="menuList"></stream-workspace-nav>
    </nav>
    <div class="user-info-wrapper" (click)="toggleLayer($event)">
      <div class="avatar">
        <img src="./assets/images/avatar.png" alt="avatar" />
      </div>
      <span class="truncate text-base font-bold text-[#333]">{{
        username$ | async
      }}</span>
      <div *ngIf="showLayer" class="user-info-layer" [@slideInOut]>
        <h3 class="mb-2 break-words text-base font-bold text-[#333]">
          {{ username$ | async }}
        </h3>
        <p class="mb-3 break-words text-sm text-[#666]">
          {{ email$ | async }}
        </p>
        <button
          class="h-10 w-full"
          nz-button
          nzType="default"
          [nzLoading]="loading"
          (click)="signOut()"
        >
          Sign out
        </button>
      </div>
    </div>
  </div>
  <main class="h-screen flex-1 overflow-y-auto" cdk-scrollable>
    <router-outlet
      #outlet="outlet"
      (activate)="onActivate($event)"
    ></router-outlet>
  </main>
  <stream-inactivity-reminder
    (onTimeout)="signOut()"
    countdownColor="#2E4B9C"
    countdownPlatform="workspace"
  ></stream-inactivity-reminder>
</div>
