import { AfterViewInit, Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { ClipboardService } from 'ngx-clipboard';

export class CopyTooltip {
  static readonly copied = 'Copied successfully';
  static readonly copy = 'Copy';
  message = CopyTooltip.copy;

  get hasCopied() {
    return this.message === CopyTooltip.copied;
  }

  reset() {
    this.message = CopyTooltip.copy;
  }

  setCopied() {
    this.message = CopyTooltip.copied;
  }
}

@Component({
  selector: 'stream-file-previewer',
  templateUrl: './file-previewer.component.html',
  styleUrls: ['./file-previewer.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FilePreviewerComponent implements AfterViewInit {
  content!: string;
  showCodeMirror = false;
  copyTooltip = new CopyTooltip();

  @ViewChild('jsonPreviewer')
  codeMirror!: CodemirrorComponent;

  constructor(
    @Inject(NZ_MODAL_DATA)
    public data: {
      title?: string;
      content: string;
    },
    private clipboardService: ClipboardService
  ) {
    try {
      this.content = JSON.stringify(JSON.parse(data.content), null, 2);
    } catch (error) {
      this.content = 'Invalid JSON';
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showCodeMirror = true;
    }, 200);
  }

  handleCopy() {
    this.clipboardService.copyFromContent(this.content);
    this.copyTooltip.setCopied();
  }
}
