import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  ResolveEnd,
  Router
} from '@angular/router';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { deployingEnvironment, loadGoogleAnalytics } from '@stream/utils';
import { LocalStorageService } from 'ngx-webstorage';
import packageJson from 'package.json';

import { environment } from '../environments/environment';
import { getBrowser } from '../utils';
import { GlobalStoreKey } from './core/store/store-keys';
import { ToastService } from '@stream/libs/common/ngx-utils';

const NOT_SHOW_FOOTER_PATH = ['dashboard', 'gp-auth', 'not-found', ''];

@Component({
  selector: 'stream-workspace-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  public showFooter = false;
  public year = new Date().getFullYear().toString();
  public version!: string;

  constructor(
    router: Router,
    private readonly title: Title,
    private route: ActivatedRoute,
    private toast: ToastService,
    private viewContainerRef: ViewContainerRef,
    private localStorage: LocalStorageService
  ) {
    (window as any)[environment.biteDebugPackageInfoKey] = packageJson;
    this.version = packageJson?.version || '1.0.0';
    const fpPromise = FingerprintJS.load();

    fpPromise
      .then(fp => fp.get())
      .then(result => {
        const { visitorId, components } = result;

        if (!this.localStorage.retrieve(GlobalStoreKey.DeviceIdentity)) {
          this.localStorage.store(GlobalStoreKey.DeviceIdentity, visitorId);
          this.localStorage.store(GlobalStoreKey.DeviceBrowserType, getBrowser());
          this.localStorage.store(
            GlobalStoreKey.DeviceOSType,
            (components.platform && components.platform.value) ?? ''
          );
        }
      })
      .catch();

    router.events.subscribe(event => {
      // 监听路由变化隐藏公共Header
      if (event instanceof NavigationEnd) {
        route.firstChild?.url.subscribe(([{ path } = { path: '' }]) => {
          this.showFooter = !NOT_SHOW_FOOTER_PATH.includes(path);
        });
        setTimeout(() => {
          this.toggleBadgeVisible(route.snapshot);
        }, 300);
      }
      if (event instanceof ResolveEnd) {
        const { data } = this.getDeepestChildSnapshot(event.state.root);
        if (data?.['title']) {
          this.title.setTitle(`${data['title']} - BiteStream | Alternative Investments`);
        } else {
          this.title.setTitle('BiteStream | Alternative Investments');
        }
      }
    });

    this.toast.setViewContainerRef(this.viewContainerRef);
  }

  toggleBadgeVisible(route: ActivatedRouteSnapshot) {
    const { data } = route;
    const badgeDom = document.querySelector('.grecaptcha-badge');
    if (!badgeDom) {
      return;
    }
    const classnames = 'grecaptcha-badge';
    if (data['showRecaptchaBadge']) {
      badgeDom.setAttribute('class', `${classnames} !visible`);
      return;
    }
    if (route.firstChild) {
      this.toggleBadgeVisible(route.firstChild);
      return;
    }
    badgeDom.setAttribute('class', `${classnames} !hidden`);
  }

  getDeepestChildSnapshot(snapshot: ActivatedRouteSnapshot) {
    let deepestChild = snapshot.firstChild;
    while (deepestChild?.firstChild) {
      deepestChild = deepestChild.firstChild;
    }
    return deepestChild || snapshot;
  }

  ngOnInit() {
    switch (deployingEnvironment()) {
      case 'PRE':
        loadGoogleAnalytics(environment.googleAnalyticsTrackingID_PRE);
        break;

      case 'PRE-EU':
      case 'PRE-EU-BLUE':
      case 'PRE-EU-GREEN':
      case 'PRE-US':
      case 'PRE-US-BLUE':
      case 'PRE-US-GREEN':
      case 'PRE-APAC':
      case 'PRE-APAC-BLUE':
      case 'PRE-APAC-GREEN':
        loadGoogleAnalytics(environment.googleAnalyticsTrackingID_PRE_EU);
        break;

      case 'PROD-EU':
      case 'PROD-EU-BLUE':
      case 'PROD-EU-GREEN':
      case 'PROD-US':
      case 'PROD-US-BLUE':
      case 'PROD-US-GREEN':
      case 'PROD-APAC':
      case 'PROD-APAC-BLUE':
      case 'PROD-APAC-GREEN':
        loadGoogleAnalytics(environment.googleAnalyticsTrackingID_PROD_EU);
        break;

      default:
        break;
    }
  }
}
