import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LocalStorageService } from 'ngx-webstorage';
import { throwError, TimeoutError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { getBrowser } from '../../../utils';
import { GlobalStoreKey } from '../store/store-keys';
import { environment } from './../../../environments/environment';
import { HttpTenantApi } from './http-api';

/** 超时时间 */
const HTTP_TIMEOUT = 30000;
/** 失败重试次数 */
const MAX_TRIES = 2;
/** 重试延时 */
const RETRY_DELAY = 1000;

const HTTP_ERROR_EXCLUDE_WHITELIST = [HttpTenantApi.GetTenantInfo.toString()];

@Injectable()
export class BaseInterceptor implements HttpInterceptor {
  constructor(private message: NzMessageService, private localStorage: LocalStorageService) {
    // 判断基础参数存在
    if (
      !this.localStorage.retrieve(GlobalStoreKey.DeviceIdentity) ||
      !this.localStorage.retrieve(GlobalStoreKey.DeviceBrowserType) ||
      !this.localStorage.retrieve(GlobalStoreKey.DeviceOSType)
    ) {
      const fpPromise = FingerprintJS.load();

      fpPromise
        .then(fp => fp.get())
        .then(result => {
          const { visitorId, components } = result;
          if (!this.localStorage.retrieve(GlobalStoreKey.DeviceIdentity)) {
            this.localStorage.store(GlobalStoreKey.DeviceIdentity, visitorId);
            this.localStorage.store(GlobalStoreKey.DeviceBrowserType, getBrowser());
            this.localStorage.store(
              GlobalStoreKey.DeviceOSType,
              (components.platform && components.platform.value) ?? ''
            );
          }
        })
        .catch();
    }
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    return next
      .handle(
        req.clone({
          url: `${environment.baseUrl}${req.url}`,
          setHeaders: {
            ...environment.headers,
            // 前端请求唯一 ID
            RequestId: uuidV4(),
            // 设备指纹
            DeviceIdentity:
              req.headers.get('DeviceIdentity') ||
              this.localStorage.retrieve(GlobalStoreKey.DeviceIdentity) ||
              '',
            // 客户端浏览器类型
            DeviceBrowserType: this.localStorage.retrieve(GlobalStoreKey.DeviceBrowserType) || '',
            // 客户端操作系统类型
            DeviceOSType: this.localStorage.retrieve(GlobalStoreKey.DeviceOSType) || ''
          }
        })
      )
      .pipe(
        timeout(HTTP_TIMEOUT),
        map(res => this.handleSuccessfulResponse(req, res)),
        catchError(err => {
          return this.handleErrorResponse(err);
        })
        // retryWhen((attempts) =>
        //   zip(range(1, MAX_TRIES + 1), attempts).pipe(
        //     mergeMap(([i, err]) => (i > MAX_TRIES ? throwError(err) : of(i))),
        //     map((i) => i * i),
        //     mergeMap((v) => timer(v * RETRY_DELAY))
        //   )
        // ),
      );
  }

  handleSuccessfulResponse(req: HttpRequest<unknown>, event: any) {
    if (event.body) {
      const { msg, code } = event.body;
      if (!HTTP_ERROR_EXCLUDE_WHITELIST.includes(req.url)) {
        if (code !== 1 && msg) {
          throw event;
        }
      }
    }

    return event;
  }

  handleErrorResponse(err: any) {
    if (err instanceof TimeoutError) {
      return throwError('Timeout');
    }
    if (err?.body?.code === 401) {
      throw new HttpErrorResponse({ status: 401 });
    }
    if (err?.error?.code === 400) {
      this.message.error(err.error.message);
      throw new HttpErrorResponse({ status: 400 });
    }
    return throwError(err);
  }
}
