export * from "./system";

const COLORS = ["#2F54EB", "#13C2C2", "#FA8C16", "#722ED1"];
export function getRandomColor() {
  return COLORS[Math.floor(Math.random() * COLORS.length)];
}

export function getColor(index: number) {
  return COLORS[index];
}

export function getBrowser() {
  const { userAgent } = navigator; //取得浏览器的userAgent字符串
  const isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
  const isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
  const isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
  const isSafari =
    userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1; //判断是否Safari浏览器
  const isChrome =
    userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

  if (isOpera) {
    return "Opera";
  }

  if (isEdge) {
    return "Edge";
  }

  if (isFF) {
    return "Firefox";
  }

  if (isSafari) {
    return "Safari";
  }

  if (isChrome) {
    return "Chrome";
  }
  return "";
}

export const deepEqual = (x: any, y: any) => {
  if (x === y) {
    return true;
  } else if (
    typeof x === "object" &&
    x != null &&
    typeof y === "object" &&
    y != null
  ) {
    if (Object.keys(x).length !== Object.keys(y).length) {
      return false;
    }

    for (const prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) {
          return false;
        }
      } else {
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
};
