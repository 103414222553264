<nz-input-group
  #input
  [nzSuffix]="disabled ? suffix : suffixTemplate"
  [nzSize]="nzSize"
>
  <input
    [(ngModel)]="value"
    nz-input
    nzSize="large"
    [type]="passwordVisible ? 'text' : 'password'"
    class="password text-base"
    [placeholder]="placeholder"
    (blur)="blur.emit()"
    autocomplete="new-password"
    [disabled]="disabled"
    (focus)="focus.emit()"
  />
</nz-input-group>
<ng-template #suffixTemplate>
  <em
    nz-icon
    [nzIconfont]="
      passwordVisible ? 'icon-a-kejianweitian1' : 'icon-a-bukejianweitian1'
    "
    (click)="passwordVisible = !passwordVisible"
    class="leading-0 select-none text-2xl"
  ></em>
</ng-template>

<ng-template #errorList>
  <ul class="error-list">
    <li
      *ngFor="let err of passwordValidators"
      [ngClass]="{'success':!ngControl.control?.errors?.[err[0]],'error':ngControl.control?.errors?.[err[0]]&&errorStatus}"
    >
      {{ err[0] }}
    </li>
  </ul>
</ng-template>
