import { NgModule } from "@angular/core";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NZ_CONFIG, NzConfig } from "ng-zorro-antd/core/config";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzListModule } from "ng-zorro-antd/list";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzMessageModule } from "ng-zorro-antd/message";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzResultModule } from "ng-zorro-antd/result";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzCollapseModule } from "ng-zorro-antd/collapse";

const ngZorroConfig: NzConfig = {};
@NgModule({
  exports: [
    NzLayoutModule,
    NzGridModule,
    NzFormModule,
    NzButtonModule,
    NzInputModule,
    NzCardModule,
    NzListModule,
    NzPopoverModule,
    NzSelectModule,
    NzMessageModule,
    NzRadioModule,
    NzModalModule,
    NzPopconfirmModule,
    NzAvatarModule,
    NzTagModule,
    NzResultModule,
    NzMenuModule,
    NzTableModule,
    NzDropDownModule,
    NzUploadModule,
    NzCheckboxModule,
    NzSpinModule,
    NzAlertModule,
    NzDividerModule,
    NzBreadCrumbModule,
    NzSwitchModule,
    NzInputNumberModule,
    NzSkeletonModule,
    NzToolTipModule,
    NzAutocompleteModule,
    NzTabsModule,
    NzBadgeModule,
    NzCollapseModule,
  ],
  providers: [{ provide: NZ_CONFIG, useValue: ngZorroConfig }],
})
export class NgZorroModule {}
