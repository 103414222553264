import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { fromEvent, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { AccountService } from '../core/service/account.service';
import { AuthService } from '../core/service/auth.service';
import { SignInStoreKey } from '../core/store/store-keys';

@Component({
  selector: 'stream-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [animate('100ms ease-in')])
    ])
  ]
})
export class LayoutComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  readonly menuList = [
    {
      path: '',
      label: 'Dashboard',
      icon: 'icon-Frame1'
    }
  ];
  showLayer = false;
  loading = false;
  sub = new Subscription();
  menuHide = false;

  email = '';
  email$ = this.accountService.workspaceAccount$.pipe(
    map(data => {
      this.email = data.gpAccountEmail;
      return data.gpAccountEmail;
    })
  );
  username$ = this.email$.pipe(
    map(email => {
      const list = (email ?? '').split('@');
      return list[0] ?? '--';
    })
  );

  ngOnInit(): void {
    this.sub = fromEvent(document, 'click').subscribe(() => {
      this.showLayer = false;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  toggleLayer(e: MouseEvent) {
    e.stopPropagation();
    this.showLayer = !this.showLayer;
  }

  signOut() {
    this.loading = true;
    this.accountService
      .logoutDevice()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(() => {
        this.authService.authStore.clearDomainToken();
        this.localStorage.clear(SignInStoreKey.SignInUserEmail);
        this.ngZone.run(() => {
          this.router.navigate(['/auth/sign-in']);
        });
      });
  }

  onActivate(_e: any): void {
    this.judgeMenuHide(this.route.snapshot);
    this.cdr.detectChanges();
  }

  judgeMenuHide(route: ActivatedRouteSnapshot): void {
    const firstChild = route.firstChild;
    if (!firstChild) {
      this.menuHide = route.data?.['menuHide'] ?? false;
      return;
    }
    return this.judgeMenuHide(firstChild);
  }
}
