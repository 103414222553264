import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FilePreviewerComponent } from './file-previewer.component';

@Injectable()
export class FilePreviewerService {
  constructor(private nzModalService: NzModalService) {}

  previewJSON(content: string) {
    const modal = this.nzModalService.create({
      nzContent: FilePreviewerComponent,
      nzWidth: 960,
      nzCentered: true,
      nzClosable: true,
      nzFooter: null,
      nzMaskClosable: false,
      nzData: {
        content
      }
    });
    return modal;
  }
}
