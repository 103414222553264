import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';
import { WorkspaceGuard } from './core/guard/workspace.guard';
import { LayoutComponent } from './layout/layout.component';
import {
  NotFoundComponent,
  NoWorkspacesComponent,
  WithoutPermissionComponent,
  DomainNotOperationalComponent,
  PrivacyPolicyComponent,
  TermsOfUseComponent
} from './layout/pages';
import { ShortLinkResolver } from './workspace/short-link.resolver';

const routes: Routes = [
  {
    path: 'auth',
    data: {
      showRecaptchaBadge: true
    },
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 's/:id',
    resolve: { fullLink: ShortLinkResolver },
    children: []
  },
  {
    path: 'gp-auth',
    data: {
      showRecaptchaBadge: true
    },
    loadChildren: () => import('./gp-auth/gp-auth.module').then(m => m.GpAuthModule)
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'no-workspaces',
    component: NoWorkspacesComponent
  },
  {
    path: 'without-permission',
    component: WithoutPermissionComponent
  },
  {
    path: 'domain-not-operational',
    component: DomainNotOperationalComponent
  },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [WorkspaceGuard],
    children: [
      {
        path: '',
        data: { title: 'My workspace' },
        loadChildren: () => import('./workspace/workspace.module').then(m => m.WorkspaceModule)
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

const isSaaSRootDomain: boolean =
  /.*?\.bitestream.co/.test(window.location.hostname) ||
  environment.customDomains.indexOf(window.location.hostname) >= 0 ||
  window.location.hostname === 'localhost' ||
  window.location.hostname === 'dev1.stream.bite.dev' ||
  window.location.hostname === 'sit1.stream.bite.dev' ||
  window.location.hostname === 'dev2.stream.bite.dev' ||
  window.location.hostname === 'sit2.stream.bite.dev' ||
  window.location.hostname === 'usa-dev.stream.bite.dev' ||
  window.location.hostname === 'pre.stream.bite.dev' ||
  window.location.hostname.indexOf('engr.bite.dev') > -1;

@NgModule({
  imports: [RouterModule.forRoot(isSaaSRootDomain ? routes : [])],
  exports: [RouterModule]
})
export class AppRoutingModule {}
