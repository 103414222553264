export enum GpCommonStatusEnum {
  Success = 'success',
  Failed = 'failed',
}

/**
 * 发送邮件状态
 */
export enum MailStatus {
  Success = 'success',
  Overrun = 'overrun',
  failed = 'failed',
  MailExistInDomain = 'mailexistindomain',
  MailNotFound = 'mailnotfound',
  TokenFailed = 'tokenfailed',
}

export interface PageRecord<T> {
  pageNo: number;
  pageSize: number;
  totalPage: number;
  totalSize: number;
  rows: T[];
}
