export enum SendRegisterEmailStatusEnum {
  /** 成功 */
  Success = 'success',
  /** 失败 */
  Failed = 'failed',
  /** 账号不存在 */
  AccountNotExist = 'accountNotExist',
  /** 账号已存在 */
  AccountExist = 'accountExist',
  /** 租户不存在 */
  TenantNotExist = 'tenantNotExist',
  /** 子域不存在 */
  DomainNotExist = 'domainNotExist',
  /** 未开启自主注册 */
  NotAutonomous = 'NotAutonomous',
  /** 邮件模板不存在 */
  EmailInstanceNotExist = 'emailInstanceNotExist',
}

export enum RegisterStatusEnum {
  Success = 'success',
  Failed = 'failed',
  AccountIsVerified = 'accountIsVerified',
  TempTokenError = 'tempTokenError',
  ReCaptchaVerifyFailed = 'reCaptchaVerifyFailed',
}

export enum LoginStatusEnum {
  Success = 'success',
  AccountNotExist = 'accountNotExist',
  TenantNotExist = 'tenantNotExist',
  EmailNotExist = 'emailNotExist',
  PasswordError = 'passwordError',
  GatewayNotPass = 'gatewayNotPass',
  PrincipalIsLocked = 'principalIsLocked',
  ReCaptchaVerifyFailed = 'reCaptchaVerifyFailed',
  AccountOrPasswordError = 'accountOrPasswordError',
  RequireMFA = 'mfaIsRequired',
  FieldIsRequired = 'fieldIsRequired',
  AccountLockoutPrompt = 'accountLockoutPrompt',
  AccountLockout = 'accountLockout',
  VerifyPasswordError = 'VerifyPasswordError'
}

export enum MFAStatusEnum {
  Success = 'success',
  Failed = 'failed',
  TicketInvalid = 'ticketInvalid',
  MFAAuthFailed = 'mfaAuthFailed',
  CodeInvalid = 'codeInvalid',
}

export enum SetPasswordStatusEnum {
  Success = 'success',
  Failed = 'failed',
  PasswordError = 'passwordError',
  PasswordRulesNotExist = 'passwordRulesNotExist',
  NewPasswordSameAsOldPassword = 'newPasswordSameAsOldPassword',
}

export const SET_PASSWORD_ERROR = {
  [SetPasswordStatusEnum.NewPasswordSameAsOldPassword]:
    'Your new password must be different from your previous password.',
};

export enum VerifyForgetPasswordStatusEnum {
  Success = 'success',
  TempTokenError = 'tempTokenError',
}

export enum VerifyActiveAccountStatusEnum {
  Success = 'SUCCESS',
  TempTokenError = 'TEMP_TOKEN_ERROR',
  TempTokenExpired = 'TEMP_TOKEN_EXPIRED',
}

export enum ActiveAccountStatusEnum {
  Success = 'SUCCESS',
  TempTokenError = 'TEMP_TOKEN_ERROR',
  TempTokenExpired = 'TEMP_TOKEN_EXPIRED',
  AddInvestAccountError = 'ADD_INVEST_ACCOUNT_ERROR',
  EmailHasBeenRegistered = 'EMAIL_HAS_BEEN_REGISTERED',
}

export enum GatewayTempTokenStatusEnum {
  Success = 'success',
  Failed = 'failed',
  TempTokenError = 'tempTokenError',
}

export enum ProductListStatusEnum {
  Success = 'success',
  ProductIsNotExist = 'productIsNotExist',
  PageProductByConditionIsNotExist = 'pageProductByConditionIsNotExist',
  PrincipalIsNotExist = 'principalIsNotExist',
  CountryIsNotOpen = 'countryIsNotOpen',
  ScreenNotOpenCountry = 'SCREEN_NOT_OPEN_COUNTRY',
  ScreenUndo = 'SCREEN_UNDO',
  ScreenNotPass = 'SCREEN_NOT_PASS',
  IdentityNotPass = 'IDENTITY_NOT_PASS',
  AssentEmpty = 'ASSENT_EMPTY',
}

export enum MarketingStatusEnum {
  Success = 'success',
  ProductIsNotExist = 'productIsNotExist',
  ParamError = 'paramError',
  ScenarioNotPassed = 'SCENARIO_NOT_PASSED',
  shareClassIsNotExist = 'shareClassIsNotExist',
}

export enum QuestionnaireStatusEnum {
  Success = 'success',
  ParamsIsRequired = 'paramsIsRequired',
  ParamsIsInvalid = 'paramsIsInvalid',
  BadRequest = 'badRequest',
  SurveyInvalid = 'surveyInvalid',
  AuthorizationRequired = 'authorizationRequired',
  TokenInvalid = 'tokenInvalid',
  QuestionConfigError = 'questionConfigError',
  ServerError = 'serverError',
}

export enum CommonDataStatusEnum {
  Valid = 'VALID',
  InValid = 'INVALID',
  Unmodified = 'UNMODIFIED',
}

export enum ChangePasswordStatusEnum {
  Success = 'success',
  Failed = 'failed',
  CurrentPasswordFailed = 'CURRENT_PASSWORD_FAILED',
}

export enum BatchInvestEnum {
  Success = 'success',
  Failed = 'failed',
  TenantNotExist = 'TENANT_NOT_EXIST',
}

export enum EditEmailEnum {
  Success = 'success',
  Failed = 'failed',
  EmailHasBeenRegistered = 'EMAIL_HAS_BEEN_REGISTERED',
}
