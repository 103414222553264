export enum AuthStoreKeyEnum {
  AccessToken = 'ACCESS_TOKEN',
  RefreshToken = 'REFRESH_TOKEN',
  PrincipalToken = 'PRINCIPAL_TOKEN',
  RegisterInfo = 'REGISTER_INFO',
  TempToken = 'TEMP_TOKEN',
  ForgetPasswordEmail = 'FORGET_PASSWORD_EMAIL',
  MFATicket = 'MFA_TICKET',
  SignInEmail = 'SIGN_IN_EMAIL',
  InviteNotification = 'INVITE_NOTIFICATION',
}

export enum EmailDelayKeyEnum {
  SignUp = 'SIGN_UP_EMAIL',
  ForgetPassword = 'FORGET_PASSWORD_EMAIL',
  SignCaptcha = 'SIGN_CAPTCHA',
  MFAEmail = 'MFA_EMAIL',
  MFASMS = 'MFA_SMS',
  MFA = 'MFA_CODE',
  MFALoginLink = 'MFA_LOGIN_LINK'
}

export enum ProductStoreKeyEnum {
  ProductListType = 'PROJECT_LIST_TYPE',
  ProductListSearchConditions = 'PRODUCT_LIST_SEARCH_CONDITIONS',
}

export enum PrincipalStoreKeyEnum {
  PrincipalList = 'PRINCIPAL_LIST',
}

export enum SetLanguageStoreKeyEnum {
  DeviceLanguage = 'DEVICE_LANGUAGE',
}

export enum SignInStoreKey {
  DomainTokenGroup = 'DOMAIN_TOKEN_GROUP',
  SignInUserEmail = 'SIGN_IN_USER_EMAIL',
  SignInUserSMS = 'SIGN_IN_USER_SMS',
  DomainToken = 'DOMAIN_TOKEN',
  SendCodeTimer = 'SIGN_IN_SEND_CODE_TIMER',
  ManageClickToken = 'MANAGE_CLICK_TOKEN',
}

export enum BaseCurrency {
  currency = 'CURRENCY',
}

export enum CustomDomain {
  Domain = 'CUSTOM_DOMAIN',
  PrefixValue = 'PREFIX_VALUE',
}

export enum ChatStoreKeyEnum {
  Messages = 'CHAT_MESSAGES',
}
