export enum GrantType {
  Create = 'create',
  Refresh = 'refresh',
}

export enum Scene {
  Bind = 'bind',
  Login = 'login',
  Unbind = 'unbind',
  List = 'list',
}

export enum FactorType {
  Email = 'email',
  Authenticator = 'authenticator',
  SMS = 'sms',
}

export interface Factor {
  activeTime?: number;
  isActive: boolean;
  isDefault: boolean;
  type: FactorType;
  mfaTicketForBind?: string;
  phoneNumber?: {
    areaCode: string;
    number: string;
  }
}

export enum AuthType {
  MFA = 'mfa',
}

export interface Agreement {
  gpTenantId: string;
  gpTenantName: string;
  hideInvestorDetails: boolean;
  id: string;
}

export interface EnterCodeResponse {
  mfaCode: string;
  mfaType: FactorType;
  ticket: string;
}