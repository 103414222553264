import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "ngx-webstorage";
import { BehaviorSubject } from "rxjs";
import {
  map,
  pluck,
  repeatWhen,
  shareReplay,
  skipWhile,
  tap,
} from "rxjs/operators";

import { HttpAccountApi, HttpSignInApi } from "../http/http-api";
import {
  AccountInfo,
  BiteClaims,
  RoleInfo,
  SetPasswordStatus,
  VerifyType,
} from "../model/account.model";
import { GpCommonStatusEnum, MailStatus } from "../model/common.model";
import { GlobalStoreKey } from "../store/store-keys";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private localStorage: LocalStorageService
  ) {}

  accountInfo$ = new BehaviorSubject<AccountInfo>({} as AccountInfo);

  accountInfo = this.accountInfo$.asObservable();

  workspaceAccount$ = this.getWorkspaceAccount().pipe(
    map((res) => res.data.data ?? {}),
    repeatWhen(() => this.authService.loginSubject),
    shareReplay(1)
  );
  roles$ = this.checkAccountAccess().pipe(
    pluck("data", "roles"),
    shareReplay(1)
  );
  get avatarText() {
    return (
      this.accountInfo$
        .getValue()
        .userName?.split(" ")
        .map((str) => str.trim()?.[0]?.toUpperCase())
        .join("") ?? ""
    );
  }

  /**
   * 登出当前账号
   */
  logout() {
    return this.logoutDevice(
      this.localStorage.retrieve(GlobalStoreKey.DeviceIdentity)
    );
  }

  /**
   * 保存密码，使用 localStorage 中的token
   * @param password 密码
   * @param temporaryToken 校验操作权限的临时token
   * @returns
   */
  setPassword(password: string, tempVerifyToken: string | null = null) {
    return this.http.post<Rest<{ status: SetPasswordStatus }>>(
      HttpAccountApi.SetPassword,
      {
        password,
        tempVerifyToken,
      }
    );
  }

  /**
   * 跳过设置密码
   */
  skipSetPassword() {
    return this.http.post<boolean>(HttpAccountApi.SetPasswordSkip, {
      gpAccountPasswordIsskip: true,
    });
  }

  /**
   * 更新用户名
   * @param username 用户名
   * @returns
   */
  setUsername(username: string) {
    return this.http.post<
      Rest<{ gpCommonStatusEnum: GpCommonStatusEnum; message?: string }>
    >(HttpAccountApi.SetUsername, { userName: username });
  }

  /**
   * 更新时区
   * @param timeZone 时区offset
   * @returns
   */
  setTimeZone(timeZone: number, timeZoneText: string) {
    return this.http.post<
      Rest<{
        gpCommonStatusEnum: GpCommonStatusEnum;
        message?: string;
      }>
    >(HttpAccountApi.SetTimeZone, {
      timeZone,
      timeZoneText,
    });
  }

  /**
   * Login Check / 登录邮箱预校验
   */
  loginCheck() {
    return this.http.get<
      Rest<{ gpAccountStatusEnum: string; isSetPassword: boolean }>
    >(HttpSignInApi.LoginCheck, {
      params: { email: this.accountInfo$.getValue().gpAccountEmail },
    });
  }

  /**
   * 获取所有登录设备
   */
  getLoginEquipment() {
    return this.http.get<Rest<BiteClaims[]>>(HttpAccountApi.GetLoginEquipment);
  }

  /**
   * 登出指定设备
   * @param deviceIdentity 设备编号
   * @returns
   */
  logoutDevice(deviceIdentity = "") {
    return this.http
      .post<Rest>(HttpAccountApi.LogoutDevice, undefined, {
        headers: {
          DeviceIdentity: deviceIdentity,
        },
      })
      .pipe(
        skipWhile(() => {
          const isCurrentDevice =
            deviceIdentity ===
            this.localStorage.retrieve(GlobalStoreKey.DeviceIdentity);
          if (isCurrentDevice) {
            this.authService.authStore.clearDomainToken();
          }
          return isCurrentDevice;
        })
      );
  }

  /**
   * 登出所有设备
   */
  logoutAllDevices() {
    return this.http.post<Rest>(HttpAccountApi.LogoutAllDevices, {}).pipe(
      tap(() => {
        this.logout();
      })
    );
  }

  logoutAllTenant(email: string) {
    return this.http.get<Rest>(HttpAccountApi.LogoutAllTenant, {
      params: { email },
    });
  }

  /**
   * 设置头像
   * @param userAvatarUrl 头像地址
   */
  setAvatar(userAvatarUrl: string) {
    return this.http.post<
      Rest<{ gpCommonStatusEnum: GpCommonStatusEnum; message?: string }>
    >(HttpAccountApi.SetAvatar, {
      userAvatarUrl,
    });
  }

  /**
   * 发送敏感操作校验邮件
   * @param verifyType 校验类型
   */
  sendVerifyCodeMail(verifyType: VerifyType) {
    return this.http.get<Rest<{ gpMailSentEnum: MailStatus }>>(
      HttpAccountApi.SendVerifyCodeMail,
      { params: { verifyType } }
    );
  }

  /**
   * 校验敏感操作验证码
   * @param code 验证码
   * @param verifyType 校验类型
   */
  checkVerifyCodeMail(code: string, verifyType: VerifyType) {
    return this.http.get<
      Rest<{ verifyResult: boolean; temporaryToken: string }>
    >(HttpAccountApi.CheckVerifyCodeMail, {
      params: {
        code,
        verifyType,
      },
    });
  }

  getWorkspaceAccount() {
    return this.http.get<
      Rest<{
        data: {
          id: string;
          gpAccountEmail: string;
          gpAccountSource: string;
          roles: string[];
        };
      }>
    >(HttpAccountApi.GetWorkspaceAccountInfo);
  }

  checkAccountAccess() {
    return this.http.get<Rest<{ status: string; roles: RoleInfo[] }>>(
      `${HttpAccountApi.checkAccountAccess}`
    );
  }
}
