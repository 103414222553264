import { NgModule } from "@angular/core";
import {
  DashboardOutline,
  DownOutline,
  EditOutline,
  FormOutline,
  LinkOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  UpOutline,
} from "@ant-design/icons-angular/icons";
import { NZ_ICONS, NzIconModule, NzIconService } from "ng-zorro-antd/icon";

const icons = [
  MenuFoldOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  FormOutline,
  LinkOutline,
  DownOutline,
  UpOutline,
  EditOutline,
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class IconsProviderModule {
  constructor(iconService: NzIconService) {
    iconService.fetchFromIconfont({
      scriptUrl: "./assets/iconfont/iconfont.js",
    });
  }
}
