<p class="my-11 flex justify-center text-3xl font-medium">Terms of Use</p>
<div class="container mx-auto">
  <p>
    These terms (and any documents referred to herein) tell you the basis on which you may make use
    of our website issued in the Cayman Islands under the domain name www.biteinvestments.com Please
    read these terms, and our Privacy & Cookies Policy (the Terms), very carefully before using the
    Website. By using the Website you confirm your acceptance of the Terms and agree to abide by
    them. If you do not accept the Terms, you should exit the Website immediately and refrain from
    using it. Ⅰ. Information about us We are a company registered in the Cayman Islands under the
    name BITE ASSET MANAGEMENT (CAYMAN) LIMITED (We/Us/Our/Bite). Our registered office is at 5th
    Floor, Anderson Square Building, 64 Shedden Road, PO Box 31325, SMB Grand Cayman, KY1-1206,
    Cayman Islands. We are registered with the Cayman Islands Monetary Authority ("CIMA") as a
    Registered Investment Management company. We provide investment management services (the
    Services) to our clients. If you have any queries about the Website or any information contained
    on it, please contact us at 5th Floor, Anderson Square Building, 64 Shedden Road, PO Box 31325,
    SMB Grand Cayman, KY1-1206, Cayman Islands or by email salessupport@biteinvestments.com. Ⅱ.
    Accessing our website Access to the Website is offered to allow you to peruse the Services we
    offer. In the event that you breach these Terms, your permission to use the Website terminates
    immediately and you must immediately destroy any downloaded or printed extracts from the
    Website. Ⅲ. Disclaimer Bite is a CIMA Registered Investment Manager. The scope of Bite's
    relevant CIMA permissions can be found on www.cima.ky/search-entities. Bite is required to
    classify customers according to CIMA classifications and is permitted to conduct investment
    business with High Net Worth Investors and Sophisticated Investors. The content of this Website
    is intended for the information of such persons only. In all cases, however, the provision of
    Services will be subject to, and provided in accordance with, detailed terms and conditions of
    business reflecting the classification of the client and ensuring the appropriate standard of
    protection in accordance with CIMA rules. In all cases the provision of the Services to any
    party is also subject to Bite obtaining satisfactory evidence of identity in accordance with its
    obligations under the relevant money laundering regulations. Nothing on this Website is intended
    to, nor should it be taken to, create any legal or contractual relationship excluding signed
    investment documents. Any transmission, downloading or sending of any information from the
    Website does not create any contractual relationship. Neither the information nor any opinions
    stated in the Website constitutes a solicitation or offer by Bite to buy or sell any securities
    or other financial instruments or provide any investment advice. The provision of investment
    services may be restricted in certain jurisdictions. You are required to acquaint yourself with
    any local laws and restrictions on the usage of this Website and the availability of any
    services described therein. The information on this Website is not intended for distribution to
    or use by any personal entity in any jurisdiction or country where such distribution would be
    contrary to local law or regulation. The content of the Website is not for publication in the
    press or elsewhere without permission of Bite. Bite does not provide legal or tax advice.
    Clients of Bite and viewers of this Website are encouraged to consult their own legal and tax
    advisers before making any investment decision. Investors should take independent legal and
    taxation and investment advice before proceeding with any type of investment activity. Whilst we
    endeavour at all times to ensure our Website information is clear, fair and not misleading and
    accurately reflects our opinions and the true facts at the date of publication, we do not hold
    the information as impartial and it should not be viewed as wholly objective. Information on
    this Website is based on sources that we believe to be reliable but we give no undertaking that
    it is accurate or complete and Bite cannot and does not guarantee the accuracy, validity,
    timeliness or completeness of any information or data made available to you for any particular
    purpose. Bite does not warrant the accuracy of the materials provided herein, either expressly
    or impliedly. Neither Bite nor any associate of it will be responsible for any errors or
    omissions or for the results obtained from the use of such information or for any loss or damage
    that could result from interception by third parties of any information made available to you
    via this Website. Bite or one of its associates may have used the information before it was
    placed on this Website. Any opinions are subject to change without notice and Bite is under no
    obligation to report or keep information accurate. Our Website is controlled and operated by
    Bite in the Cayman Islands. We make no representations that materials in the Website are
    appropriate or available for use in other locations. Those who choose to access the Website from
    other locations do so at their own risk and are responsible for compliance with any and all
    local laws, if and to the extent local laws are applicable. Ⅳ. Intellectual property rights The
    Website is registered to Bite and any and all intellectual property rights in the Website
    including, but not limited to, copyright and database rights and any logos or trademarks
    (whether registered or unregistered) are licensed to Bite at all times. The content of the
    materials on the Website are protected under applicable copyrights, trademarks and other
    proprietary and/or intellectual property rights. You do not acquire ownership rights to any
    materials viewed through this Website. You are permitted to print copies and may download
    extracts of the content on the Website for your own personal use for information purposes only,
    and may draw the attention of others within your organisation to material posted on the Website,
    provided that: our status as authors of material on the Website is always acknowledged; No
    documents or related graphics on the Website are modified, amended or altered in any way, which
    includes (without limitation) the removal of any copyright or other proprietary notices
    contained in the Website; No graphics, video, audio or sequences on the Website are used
    separately from the corresponding text; You do not use any part of the content on the Website
    for commercial purposes without our prior written consent; You do not copy, redistribute,
    display or publish any part of this Website; any information on the Website which is marked as
    being confidential is treated as such and is not disclosed to any third party; and You agree
    that you will not otherwise use any part of the Website in contravention of these Terms. Ⅴ.
    Unlawful or prohibited use As a condition of your use of the Bite Website and any Services
    provided by Bite you will not: Commit or encourage any conduct which would be considered a
    criminal offence, give rise to civil liability, or otherwise be contrary to the law of or
    infringe the rights of any third party, in any country in the world; Send or receive any
    material which is threatening, offensive, defamatory, discriminatory, seditious, pornographic,
    blasphemous, liable to incite racial hatred or which may be abusive, indecent, obscene or
    menacing, or in breach of confidence, copyright, privacy or any other rights. In the event we
    believe any material falls within any or all of these categories we shall be entitled to remove
    it without notice; Collect or store personal data about other users; Insert or innocently,
    knowingly or recklessly transmit or distribute, without limitation, a virus, worm, Trojan
    horses, time bombs, trap doors or any other computer code, files or programs or repetitive
    requests designed to interrupt, destroy or limit the functionality of any computer software or
    hardware or telecommunications equipment or to diminish the quality of, interfere with the
    performance of or impair the functionality of the Website; otherwise act in such a way as to
    damage, disable, overburden or impair the Website or the Services provided by Bite; Upload,
    post, email or otherwise transmit or post links to any content that facilitates hacking; Attempt
    to gain unauthorised access to any aspect of our Website, the server on which our Website is
    stored or any server, computer or database connected to our Website. You must not attack our
    Website via a denial-of-service attack or a distributed denial-of service attack; Upload, post,
    email or otherwise transmit links to any content that infringes the rights of any third party;
    Circumvent, or attempt to seek to circumvent, any of the security safeguards of the Website;
    Cause annoyance or inconvenience to other people accessing the Website; post any personal
    information regarding the employees of your company or other individuals; Send any unsolicited
    advertising or other promotional material, commonly referred to as "spam", "junk mail", "chain
    letter", "pyramid schemes" or any other form of solicitation by email or by any other electronic
    means; Send email or any other type of electronic message with the intention or result of
    affecting the performance or functionality of any computer facilities; or permit any third party
    to do any of the above.
  </p>
</div>
