import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from "@angular/router";
import { DriveService } from "../core/service/driver.service";
import { Observable, filter, map, tap } from "rxjs";
import { GpCommonStatusEnum } from "../core/model/common.model";

export const ShortLinkResolver: ResolveFn<Observable<string>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(DriveService)
    .getFullUrlByHash(route.params["id"])
    .pipe(
      filter(
        ({ data: { gpCommonStatusEnum } }) =>
          gpCommonStatusEnum === GpCommonStatusEnum.Success
      ),
      map(
        ({
          data: {
            commonShortlink: { originalUrl },
          },
        }) => originalUrl
      ),
      tap((path) => window.open(path, "_self"))
    );
};
