<div class="flex h-full w-full items-center justify-center">
  <div>
    <div class="flex justify-center">
      <img src="./assets/images/no_workspace.svg" class="h-40" alt="" />
    </div>
    <p class="mt-9 text-2xl font-semibold">
      You haven't joined any workspaces.
    </p>
    <div class="flex justify-center">
      <button
        nz-button
        nzType="primary"
        class="mt-10 h-12 w-48"
        routerLink="/auth/sign-in"
      >
        Sign in
      </button>
    </div>
  </div>
</div>
