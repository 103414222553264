import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CodeInputModule } from 'angular-code-input';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { InputClearableComponent } from './components/input-clearable/input-clearable.component';
import { MultiEmailInputComponent } from './components/multi-email-input/multi-email-input.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { RandomColorDirective } from './directives/random-color.directive';
import { IconsProviderModule } from './icons-provider.module';
import { NgZorroModule } from './ng-zorro.module';
import { CopyButtonComponent } from './components/copy-button/copy-button.component';

const SHARED_COMPONENTS = [
  PasswordInputComponent,
  MultiEmailInputComponent,
  InputClearableComponent,
  CopyButtonComponent
];
@NgModule({
  declarations: [...SHARED_COMPONENTS, RandomColorDirective],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgZorroModule,
    IconsProviderModule,
    ClipboardModule,
    CodeInputModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroModule,
    IconsProviderModule,
    ...SHARED_COMPONENTS,
    RandomColorDirective,
    CodeInputModule
  ]
})
export class SharedModule {}
