import {
  AfterViewInit,
  Component,
  ComponentRef,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  IToastUpLoadParamsType,
  ToastAction,
  ToastService
} from '@stream/libs/common/ngx-utils/services/toast';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'stream-toast-virtual-common',
  template: `
    <ng-template #refToastVirtual>
      <div class="toast-virtual-ref-wrap">
        <svg
          class="icon toast-virtual-ref-icon"
          [style]="{
            display: ['success'].includes(action) ? 'block' : 'none'
          }"
          viewBox="0 0 1024 1024"
          width="1em"
          height="1em"
        >
          <path
            d="M512 0c282.7776 0 512 229.2224 512 512s-229.2224 512-512 512S0 794.7776 0 512 229.2224 0 512 0z m292.5568 335.872a34.2016 34.2016 0 0 0-48.384 0l-319.8464 308.0704-145.2544-137.7792a34.2016 34.2016 0 0 0-48.3328 48.3328l169.3696 161.8944c13.312 13.312 35.0208 13.312 48.3328 0l344.064-332.2368a34.2016 34.2016 0 0 0 0-48.3328z"
            fill="#4DB51A"
          ></path>
        </svg>

        <svg
          class="icon toast-virtual-ref-icon"
          [style]="{
            display: ['warning', 'failed', 'malicious'].includes(action) ? 'block' : 'none'
          }"
          viewBox="0 0 1024 1024"
          width="1em"
          height="1em"
        >
          <path
            d="M512 0c282.7776 0 512 229.2224 512 512s-229.2224 512-512 512S0 794.7776 0 512 229.2224 0 512 0z m0 712.192a46.592 46.592 0 1 0 0 93.0304 46.592 46.592 0 0 0 0-93.0816z m0-526.0288a41.8816 41.8816 0 0 0-41.8816 41.9328v381.6448a41.8816 41.8816 0 0 0 83.7632 0V228.096A41.8816 41.8816 0 0 0 512 186.1632z"
            fill="#EC8F05"
          ></path>
        </svg>

        <svg
          class="icon toast-virtual-ref-icon"
          [style]="{
            display: ['error'].includes(action) ? 'block' : 'none'
          }"
          viewBox="0 0 1024 1024"
          width="1em"
          height="1em"
        >
          <path
            d="M512 0C229.2224 0 0 229.2224 0 512s229.2224 512 512 512 512-229.2224 512-512S794.7776 0 512 0z m240.9984 703.2832c12.5952 12.4416 12.8 32.6144 0.512 45.312a32.1536 32.1536 0 0 1-45.312 0.512L512 556.8l-196.1984 192.2048a31.9488 31.9488 0 0 1-45.312-0.512 31.9488 31.9488 0 0 1 0.512-45.312L466.2784 512 271.0016 320.7168a32.1536 32.1536 0 0 1-0.512-45.312 32.1536 32.1536 0 0 1 45.312-0.512L512 467.2l196.1984-192.2048a31.9488 31.9488 0 0 1 45.312 0.512 31.9488 31.9488 0 0 1-0.512 45.312L557.7216 512l195.2768 191.2832z"
            fill="#DC3535"
          ></path>
        </svg>

        <svg
          class="icon toast-virtual-ref-icon toast-virtual-ref-icon-animate"
          [style]="{
            display: ['uploading'].includes(action) ? 'block' : 'none'
          }"
          viewBox="0 0 1024 1024"
          width="1em"
          height="1em"
        >
          <path
            d="M688 207.168A352 352 0 1 0 864 512 48 48 0 0 1 960 512a448 448 0 1 1-224-387.968 48 48 0 0 1-48 83.136z"
            fill="#333333"
          ></path>
        </svg>

        <svg
          class="icon toast-virtual-ref-icon toast-virtual-ref-icon-animate"
          [style]="{
            display: ['scanning'].includes(action) ? 'block' : 'none'
          }"
          viewBox="0 0 1024 1024"
          width="1em"
          height="1em"
        >
          <path
            d="M37.312 512A474.688 474.688 0 0 1 512 37.312a48 48 0 0 1 48 48v341.312a48 48 0 0 1-96 0V136.32A378.752 378.752 0 1 0 890.688 512a48 48 0 0 1 96 0A474.688 474.688 0 1 1 37.312 512z"
            fill="#333333"
          ></path>
          <path
            d="M512 597.312a85.312 85.312 0 1 0 0-170.688 85.312 85.312 0 0 0 0 170.688z"
            fill="#333333"
          ></path>
        </svg>

        <span class="toast-virtual-ref-text">{{ msg }}</span>
      </div>
    </ng-template>
  `,
  styleUrls: ['./toast-virtual-common.component.less']
})
export class ToastVirtualCommonComponent implements AfterViewInit {
  @ViewChild('refToastVirtual')
  refToastVirtual!: TemplateRef<any>;

  public msg: string = '';
  public action!: ToastAction;

  @Output()
  createEvent = new EventEmitter<any>();

  ngAfterViewInit() {
    this.createEvent.emit();
  }
}
