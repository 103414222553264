import { ScenarioType } from './interfaces';

/** Restful风格 */
export interface Restful<T = unknown> {
  message: string;
  data: T;
  code: number;
}

/** 统一请求状态码 */
export enum HttpStatusEnum {
  Success = 'success',
  Failed = 'failed'
}

export interface PageRequest {
  current: number;
  size?: number;
}

/** 分页返回格式 */
export interface Page<T = unknown> {
  /** 查询数据 */
  records: T[];
  /** 当前页 */
  current: PageRequest['current'];
  /** 总条数 */
  total: number;
  /** 每页条数 */
  size: number;
  /** 总页数 */
  pages: number;
  /** 排序 */
  orders: { asc: boolean; column: string }[];
}

export interface I18n {
  en: string;
  zh: string;
}

export enum ActionType {
  EnterScenario = 'ENTER_SCENARIO'
}

export interface NextAction {
  nextActionType: string;
  auto: boolean;
  values?: {
    icon: string;
    title: I18n;
    content: I18n;
    label: I18n;
    actions: {
      text: string;
      type: ActionType;
      params: {
        scenarioType: ScenarioType;
      };
    }[];
  };
}

export enum ReCaptchaActionEnum {
  LOGIN = 'login',
  REGISTER = 'register'
}

export enum ReCaptchaVersionEnum {
  V2 = 'V2',
  V3 = 'V3'
}

export interface ReCaptchaPayload {
  reCaptchaResponse: string;
  reCaptchaVersion: string;
}
