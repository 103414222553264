import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';

import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceGuard implements CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivateChild() {
    const workspaceToken = this.authService.authStore.workspaceToken;
    if (!workspaceToken) {
      return this.router.parseUrl('/auth/sign-in');
    }
    return true;
  }
}
