import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { NgxWebstorageModule } from 'ngx-webstorage';

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxWebstorageModule],
})
export class CountModule {
  static injector: Injector;

  constructor(injector: Injector) {
    CountModule.injector = injector;
  }
}
