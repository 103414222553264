<h2 *nzModalTitle class="font-bold">{{ data.title ?? 'Preview JSON' }}</h2>

<div class="group relative max-h-[90vh] min-h-[50vh]">
  <div
    [nz-tooltip]="copyTooltip.message"
    [nzTooltipMouseEnterDelay]="0.5"
    class="copy-button hidden group-hover:flex"
    [class.copied]="copyTooltip.hasCopied"
    (click)="handleCopy()"
    (mouseleave)="copyTooltip.reset()"
  >
    <em class="text-2xl text-inherit" nz-icon nzIconfont="icon-Base"></em>
  </div>
  <ngx-codemirror
    #jsonPreviewer
    class="json-previewer"
    *ngIf="showCodeMirror"
    [(ngModel)]="content"
    [options]="{
      lineNumbers: true,
      mode: { name: 'javascript', json: true },
      indentWithTabs: true,
      smartIndent: true,
      readOnly: true,
      theme: 'bite',
      scrollbarStyle: 'null',
      showCursorWhenSelecting: false
    }"
  ></ngx-codemirror>
</div>
