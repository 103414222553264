import { FormControl, ValidatorFn, Validators } from "@angular/forms";

const COLOR_REGEXP = /^#[0-9a-fA-F]{6}$/;
export class BiteValidators extends Validators {
  static color = ((control: FormControl) => {
    if (!control.value) {
      return null;
    }
    return COLOR_REGEXP.test(control.value) ? null : { color: true };
  }) as ValidatorFn;
}
