import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'stream-input-clearable',
  template: `
    <em
      nz-icon
      nzTheme="fill"
      nzType="close-circle"
      *ngIf="control?.value"
      (click)="control?.setValue('')"
    ></em>
  `,
  styles: [],
})
export class InputClearableComponent {
  @Input()
  control: AbstractControl | null = null;
}
